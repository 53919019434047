import React from "react";

const AboutUs = () => {
  return (
    <div>
      <div>
        {/* Breadcumb */}
        <div className="h-32 bg-gray-800 flex flex-col items-center justify-center text-lg font-semibold text-white">
          Home / About Us
        </div>

        {/* Banner */}
        <div className="flex flex-wrap justify-evenly py-20 items-center   ">
          <div className="w-[600px]">
            <img
              src={require("../asserts/aboutus1.jpg")}
              className="rounded-lg  border-t-4 border-red-800"
            />
          </div>
          <div className="">
            <div className="flex flex-row gap-x-4 text-3xl text-gray-800">
              <i class="fa-brands fa-square-instagram"></i>
              <i class="fa-brands fa-square-facebook"></i>
              <i class="fa-brands fa-square-x-twitter"></i>
            </div>
            <div className="py-6 space-y-6 text-gray-800">
              <h2 className="text-8xl font-semibold ">About Us</h2>
              <p className="w-[500px]">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
            <div className=" flex flex-row gap-x-4 ">
              <button className="bg-red-800 text-white px-5 py-3 transition-transform hover:scale-105 rounded-lg uppercase">
                Explore
                <i class="fa-solid fa-arrow-down ml-4"></i>
              </button>
            </div>
          </div>
        </div>

        {/* Section 1 */}
        <div className="flex justify-evenly items-start px-20 py-20">
          <div className="w-1/2 text-gray-800 space-y-8 pr-12">
            <h2 className="font-bold text-4xl">What is Lorem Ipsum?</h2>
            <p className="">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
            </p>
            <div className=" flex flex-row gap-x-4 ">
              <button className="bg-red-800 text-white px-5 py-2 transition-transform hover:scale-105 rounded-lg uppercase">
                Learn More
                <i class="fa-solid fa-arrow-down ml-4"></i>
              </button>
            </div>
          </div>
          <div className=" bg-red-800 h-[350px] rounded-lg">
            <img
              c
              src={require("../asserts/aboutus1.jpg")}
              className="rounded-lg pt-6 pr-6 hover:pr-0 hover:pt-0 hover:h-[350px] h-[350px] transition-all ease-in cursor-pointer duration-200"
            />
          </div>
        </div>
        {/* Section 2 */}
        <div className="flex justify-evenly items-start px-20 py-20 bg-gray-200">
          <div className=" bg-red-800 h-[350px] rounded-lg">
            <img
              c
              src={require("../asserts/aboutus1.jpg")}
              className="rounded-lg pt-6 pr-6 hover:pr-0 hover:pt-0 hover:h-[350px] h-[350px] transition-all ease-in cursor-pointer duration-200"
            />
          </div>
          <div className="w-1/2 text-gray-800 space-y-8 pl-12">
            <h2 className="font-bold text-4xl">What is Lorem Ipsum?</h2>
            <p className="">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
            </p>
            <div className=" flex flex-row gap-x-4 ">
              <button className="bg-red-800 text-white px-5 py-2 transition-transform hover:scale-105 rounded-lg uppercase">
                Learn More
                <i class="fa-solid fa-arrow-down ml-4"></i>
              </button>
            </div>
          </div>
        </div>
        {/* Section 3 */}
        <div className="flex justify-evenly items-start px-20 py-20">
          <div className="w-1/2 text-gray-800 space-y-8 pr-12">
            <h2 className="font-bold text-4xl">What is Lorem Ipsum?</h2>
            <p className="">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
            </p>
            <div className=" flex flex-row gap-x-4 ">
              <button className="bg-red-800 text-white px-5 py-2 transition-transform hover:scale-105 rounded-lg uppercase">
                Learn More
                <i class="fa-solid fa-arrow-down ml-4"></i>
              </button>
            </div>
          </div>
          <div className=" bg-red-800 h-[350px] rounded-lg">
            <img
              c
              src={require("../asserts/aboutus1.jpg")}
              className="rounded-lg pt-6 pr-6 hover:pr-0 hover:pt-0 hover:h-[350px] h-[350px] transition-all ease-in cursor-pointer duration-200"
            />
          </div>
        </div>
        {/* Section 4 */}
        <div className="bg-gray-200 px-48 py-20 ">
          <div className="text-center text-gray-800">
            <p className="text-red-800 font-semibold">Testimonials</p>
            <h2 className="text-5xl font-semibold">What Clients are saying?</h2>
          </div>
          <div className="flex flex-wrap justify-center gap-8 py-8">
            <div className="w-[40%] bg-white p-6 space-y-2 rounded-lg shadow-xl">
              <h4 className="text-lg font-semibold">
                Its has been a great help by Alchemy
              </h4>
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour,{" "}
              </p>
              <div className="flex gap-2 items-center py-2">
                <div>
                  <img
                    src={require("../asserts/profile.jpg")}
                    className="rounded-full  border-l-2 border-r-2 border-red-800 w-12"
                  />
                </div>
                <div className="">
                  <p className="text-[15px] font-semibold text-red-800">
                    Aman Singh
                  </p>
                  <p className="text-[12px]">CEO of ABC Company</p>
                </div>
              </div>
            </div>
            <div className="w-[40%] bg-white p-6 space-y-2 rounded-lg shadow-xl">
              <h4 className="text-lg font-semibold">
                Its has been a great help by Alchemy
              </h4>
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour,{" "}
              </p>
              <div className="flex gap-2 items-center py-2">
                <div>
                  <img
                    src={require("../asserts/profile.jpg")}
                    className="rounded-full  border-l-2 border-r-2 border-red-800 w-12"
                  />
                </div>
                <div className="">
                  <p className="text-[15px] font-semibold text-red-800">
                    Aman Singh
                  </p>
                  <p className="text-[12px]">CEO of ABC Company</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Section 5 */}
        <div className="flex flex-col item-center justify-center h-[400px] px-80  text-center space-y-5">
          <h2 className="text-4xl font-semibold">
            Ready To Make Me a Call For Grow Your Business In Next Level?
          </h2>
          <p className="px-20">
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour
          </p>
          <div className=" flex flex-row gap-x-4 justify-center ">
            <button className="bg-red-800 text-white px-5 py-2 transition-transform hover:scale-105 rounded-lg uppercase">
              Contact Us
              <i class="fa-solid fa-arrow-down ml-4"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
