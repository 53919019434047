import React from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Services = () => {
  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    // speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div>
      <div>
        {/* Breadcumb */}
        <div className="h-32 bg-gray-800 flex flex-col items-center justify-center text-lg font-semibold text-white">
          <p>
            <a className="hover:underline" href="/">
              {" "}
              Home
            </a>{" "}
            /{" "}
            <a className="hover:underline" href="/services">
              Services
            </a>
          </p>
        </div>

        {/* Section 1 */}
        <div className="flex flex-wrap   justify-center items-start lg:px-40 px-4 py-10 w-full gap-4 lg:gap-0">
          <div className="text-[18px]  lg:pb-20 pb-8 lg:px-0 font-bold text-center">
            <p className="">
              In the fast-paced world of pharmaceuticals & FMCG, efficient
              supply chain management is paramount to ensure timely delivery of
              crucial medicines and healthcare products. With our 40 years of
              expertise and dedication, we ensure seamless transportation and
              distribution of pharmaceutical goods, maintaining the integrity
              and efficacy of the products throughout the supply chain.
            </p>
          </div>
          <div className="lg:w-[500px] text-gray-800 space-t-8 lg:pr-12 button-pop-in text-center lg:text-left">
            <h2 className="font-bold lg:text-4xl text-2xl pb-6">
              Carrying & Forwarding Agent Services
            </h2>
            <p className="lg:text-justify">
              Our team efficiently handles CFA operations for Pharmaceutical and
              FMCG companies for West India. We handle over 7000+ invoices and
              dispatch approximately gross volume of 1 lakh shippers monthly
              across India.....
            </p>

            <div className=" flex flex-row gap-x-4 py-6 justify-center lg:justify-start">
              <a
                href="carrying-&-forwarding-agent-services"
                className="bg-red-800 text-white px-5 py-3 transition-transform hover:scale-105 rounded-lg "
              >
                View More
              </a>
            </div>
          </div>
          <Slider
            autoplay
            autoplaySpeed={3000}
            infinite={true}
            {...settings}
            className="  lg:mt-0 mt-4 rounded-lg button-pop-in lg:w-[400px] w-[330px] "
          >
            <img
              src={require("../asserts/Carry & Forwarding Agent.jpg")}
              className="rounded-lg lg:pt-2 lg:pr-2 lg:hover:pr-0 lg:hover:pt-0 h-[300px]   transition-all ease-in cursor-pointer duration-200"
            />
            <img
              src={require("../asserts/Carrying & Forwarding Agent.jpg")}
              className="rounded-lg lg:pt-2 lg:pr-2 lg:hover:pr-0 lg:hover:pt-0 h-[300px] transition-all ease-in cursor-pointer duration-200"
            />
          </Slider>
        </div>
        {/* Section 2 */}
        <div className="flex flex-wrap justify-center items-start lg:px-40 px-4 py-10 w-full gap-4 lg:gap-0">
          <div className="hidden lg:block">
            <Slider
              autoplay
              autoplaySpeed={3000}
              infinite={true}
              {...settings}
              className=" md:hidden lg:mt-0 mt-4 rounded-lg button-pop-in lg:w-[400px] w-[330px] "
            >
              <img
                src={require("../asserts/Super Stockist Distribution.jpg")}
                className="rounded-lg lg:pt-2 lg:pr-2 lg:hover:pr-0 lg:hover:pt-0 h-[300px]  transition-all ease-in cursor-pointer duration-200"
              />
              <img
                src={require("../asserts/Super Stockist.jpg")}
                className="rounded-lg lg:pt-2 lg:pr-2 lg:hover:pr-0 lg:hover:pt-0 h-[300px] transition-all ease-in cursor-pointer duration-200"
              />
            </Slider>
          </div>
          <div className="lg:w-[500px] text-gray-800 space-t-8 lg:pl-12 button-pop-in text-center lg:text-left">
            <h2 className="font-bold lg:text-4xl text-2xl pb-6">
              Super Stockist/Super Distributor Operations
            </h2>
            <p className="lg:text-justify">
              RSIPL is regional, state-wide stockist/distributor for well-known
              pharmaceutical companies. As a super stockist, we play a pivotal
              role in the supply chain eco system acting as key intermediaries
              between manufacturer and their end customers....
            </p>
            <div className=" flex flex-row gap-x-4 py-6 justify-center lg:justify-start">
              <a
                href="/super-stockist-super-Distributor-operations"
                className="bg-red-800 text-white px-5 py-3 transition-transform hover:scale-105 rounded-lg "
              >
                View More
              </a>
            </div>
          </div>
          <div className="lg:hidden block">
            <Slider
              autoplay
              autoplaySpeed={3000}
              infinite={true}
              {...settings}
              className="lg:mt-0 mt-4 rounded-lg button-pop-in lg:w-[400px] w-[330px] "
            >
              <img
                src={require("../asserts/Super Stockist Distribution.jpg")}
                className="rounded-lg lg:pt-2 lg:pr-2 lg:hover:pr-0 lg:hover:pt-0 h-[300px]  transition-all ease-in cursor-pointer duration-200"
              />
              <img
                src={require("../asserts/Super Stockist.jpg")}
                className="rounded-lg lg:pt-2 lg:pr-2 lg:hover:pr-0 lg:hover:pt-0 h-[300px] transition-all ease-in cursor-pointer duration-200"
              />
            </Slider>
          </div>
        </div>
        {/* Section 3*/}
        <div className="flex flex-wrap justify-center items-start lg:px-40 px-4 py-10 w-full gap-4 lg:gap-0">
          <div className="lg:w-[500px] text-gray-800 space-t-8 lg:pr-12 button-pop-in text-center lg:text-left">
            <h2 className="font-bold lg:text-4xl text-2xl pb-6">
              Cold Chain Distribution
            </h2>
            <p className="lg:text-justify">
              Cold Chain distribution forms a critical part of the
              pharmaceutical supply chain. At RSIPL, we carry out cold chain
              distribution adhering to utmost quality assurance throughout
              storage and transit....
            </p>
            <div className=" flex flex-row gap-x-4 py-6 justify-center lg:justify-start">
              <a
                href="/cold-chain-distribution"
                className="bg-red-800 text-white px-5 py-3 transition-transform hover:scale-105 rounded-lg "
              >
                View More
              </a>
            </div>
          </div>
          <Slider
            autoplay
            autoplaySpeed={3000}
            infinite={true}
            {...settings}
            className="  lg:mt-0 mt-4 rounded-lg button-pop-in lg:w-[400px] w-[330px] "
          >
            <img
              src={require("../asserts/Cold Chain Distribution 1.jpg")}
              className="rounded-lg lg:pt-2 lg:pr-2 lg:hover:pr-0 lg:hover:pt-0 h-[300px]  transition-all ease-in cursor-pointer duration-200"
            />
            <img
              src={require("../asserts/Cold Chain Distribution 2.jpg")}
              className="rounded-lg lg:pt-2 lg:pr-2 lg:hover:pr-0 lg:hover:pt-0 h-[300px] transition-all ease-in cursor-pointer duration-200"
            />
          </Slider>
        </div>
        {/* Section 4*/}
        <div className="flex flex-wrap justify-center items-start lg:px-40 px-4 py-10 w-full gap-4 lg:gap-0">
          <div className="hidden lg:block">
            <Slider
              autoplay
              autoplaySpeed={3000}
              infinite={true}
              {...settings}
              className="  lg:mt-0 mt-4 rounded-lg button-pop-in lg:w-[400px] w-[330px] "
            >
              <img
                src={require("../asserts/Promotional Material Handling (2).jpg")}
                className="rounded-lg lg:pt-2 lg:pr-2 lg:hover:pr-0 lg:hover:pt-0 h-[300px]  transition-all ease-in cursor-pointer duration-200"
              />
              <img
                src={require("../asserts/Promotional material Handling.jpg")}
                className="rounded-lg lg:pt-2 lg:pr-2 lg:hover:pr-0 lg:hover:pt-0 h-[300px] transition-all ease-in cursor-pointer duration-200"
              />
            </Slider>
          </div>
          <div className="lg:w-[500px] text-gray-800 space-t-8 lg:pl-12 button-pop-in text-center lg:text-left">
            <h2 className="font-bold lg:text-4xl text-2xl pb-6">
              Promotional Material Handling
            </h2>
            <p className="lg:text-justify">
              Ramdas Sales provides quality services in the field of Promotional
              Material Handling. Promotional material includes literature,
              samples, etc. We cater to specific requirements of Pharmaceutical
              Promotional Operations with utmost accuracy and tracking....
            </p>
            <div className=" flex flex-row gap-x-4 py-6 justify-center lg:justify-start">
              <a
                href="/promotional-material-handling"
                className="bg-red-800 text-white px-5 py-3 transition-transform hover:scale-105 rounded-lg "
              >
                View More
              </a>
            </div>
          </div>
          <div className="lg:hidden block">
            <Slider
              autoplay
              autoplaySpeed={3000}
              infinite={true}
              {...settings}
              className="  lg:mt-0 mt-4 rounded-lg button-pop-in lg:w-[400px] w-[330px] "
            >
              <img
                src={require("../asserts/Promotional Material Handling (2).jpg")}
                className="rounded-lg lg:pt-2 lg:pr-2 lg:hover:pr-0 lg:hover:pt-0 h-[300px]  transition-all ease-in cursor-pointer duration-200"
              />
              <img
                src={require("../asserts/Promotional material Handling.jpg")}
                className="rounded-lg lg:pt-2 lg:pr-2 lg:hover:pr-0 lg:hover:pt-0 h-[300px] transition-all ease-in cursor-pointer duration-200"
              />
            </Slider>
          </div>
        </div>
        {/* Section 5 */}
        <div className="flex flex-wrap justify-center items-start lg:px-40 px-4 py-10 w-full gap-4 lg:gap-0">
          <div className="lg:w-[500px] text-gray-800 space-t-8 lg:pr-12 button-pop-in text-center lg:text-left">
            <h2 className="font-bold lg:text-4xl text-2xl pb-6">
              Reverse logistics
            </h2>
            <p className="lg:text-justify">
              Reverse logistics in pharmaceutical distribution involves the
              management of returned products, expired medications, and the
              overall optimization of the supply chain in the reverse direction.
              The pharmaceutical industry operates within strict regulatory
              frameworks. Proper handling of returned medications is not just
              good business practice but also a legal requirement....
            </p>
            <div className=" flex flex-row gap-x-4 py-6 justify-center lg:justify-start">
              <a
                href="/reverse-logistics"
                className="bg-red-800 text-white px-5 py-3 transition-transform hover:scale-105 rounded-lg "
              >
                View More
              </a>
            </div>
          </div>
          <Slider
            autoplay
            autoplaySpeed={3000}
            infinite={true}
            {...settings}
            className="  lg:mt-0 mt-4 rounded-lg button-pop-in lg:w-[400px] w-[330px] "
          >
            <img
              src={require("../asserts/Reverse Logistics...jpeg")}
              className="rounded-lg lg:pt-2 lg:pr-2 lg:hover:pr-0 lg:hover:pt-0 h-[300px]  transition-all ease-in cursor-pointer duration-200"
            />
            <img
              src={require("../asserts/Reverse Logistics.jpeg")}
              className="rounded-lg lg:pt-2 lg:pr-2 lg:hover:pr-0 lg:hover:pt-0 h-[300px] transition-all ease-in cursor-pointer duration-200"
            />
          </Slider>
        </div>
        {/* Section 6 */}
        <div className="flex flex-wrap justify-center items-start lg:px-40 px-4 py-10 w-full gap-4 lg:gap-0">
          <div className="hidden lg:block">
            <Slider
              autoplay
              autoplaySpeed={3000}
              infinite={true}
              {...settings}
              className="  lg:mt-0 mt-4 rounded-lg button-pop-in lg:w-[400px] w-[330px] "
            >
              <img
                src={require("../asserts/services.jpg")}
                className="rounded-lg lg:pt-2 lg:pr-2 lg:hover:pr-0 lg:hover:pt-0 h-[400px]  transition-all ease-in cursor-pointer duration-200"
              />
              <img
                src={require("../asserts/services.jpg")}
                className="rounded-lg lg:pt-2 lg:pr-2 lg:hover:pr-0 lg:hover:pt-0 h-[400px] transition-all ease-in cursor-pointer duration-200"
              />
            </Slider>
          </div>
          <div className="lg:w-[500px] text-gray-800 space-t-8 lg:pl-12 button-pop-in text-center lg:text-left">
            <h2 className="font-bold lg:text-4xl text-2xl pb-6">
              Value Added Services
            </h2>
            <p className="lg:text-justify">
              In today's dynamic healthcare landscape, we understand the
              critical role that value-added services play in optimizing
              efficiency, improving patient outcomes, and ensuring regulatory
              compliance. Our commitment is to provide innovative solutions that
              go beyond traditional distribution, empowering our partners to
              thrive in an increasingly competitive market. We are an extension
              of your team. We offer dependability & an array of services that
              enable you to focus on their core strength and leave the
              fulfilment expertise to us thus streamlining your final customer
              experience....
            </p>
            <div className=" flex flex-row gap-x-4 py-6 justify-center lg:justify-start">
              <a
                href="/value-added-services"
                className="bg-red-800 text-white px-5 py-3 transition-transform hover:scale-105 rounded-lg "
              >
                View More
              </a>
            </div>
          </div>
          <div className="lg:hidden block">
            <Slider
              autoplay
              autoplaySpeed={3000}
              infinite={true}
              {...settings}
              className="  lg:mt-0 mt-4 rounded-lg button-pop-in lg:w-[400px] w-[330px] "
            >
              <img
                src={require("../asserts/services.jpg")}
                className="rounded-lg lg:pt-2 lg:pr-2 lg:hover:pr-0 lg:hover:pt-0 h-[300px]  transition-all ease-in cursor-pointer duration-200"
              />
              <img
                src={require("../asserts/services.jpg")}
                className="rounded-lg lg:pt-2 lg:pr-2 lg:hover:pr-0 lg:hover:pt-0 h-[300px] transition-all ease-in cursor-pointer duration-200"
              />
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
