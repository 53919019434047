import React from "react";

const Material = () => {
  return (
    <div>
      <div className="shadow-2xl lg:w-[800px] m-auto px-10 py-20 space-y-4 text-justify ">
        <h2 className="text-2xl font-semibold">
          Promotional Material Handling
        </h2>
        <p className="">
          Ramdas Sales provides quality services in the field of Promotional
          Material Handling. Promotional material includes literature, samples,
          etc. We cater to specific requirements of Pharmaceutical Promotional
          Operations with utmost accuracy and tracking.
        </p>
        <p className="text-lg font-semibold">
          {" "}
          Key features of our Promotional Material Operations include:
        </p>

        <ul className="space-y-4">
          <li className="list-disc">
            Special Dispatches are made within 24 hours from the time of
            allocation. Monthly dispatches are carried out as per the pre
            specified timeline.
          </li>
          <li className="list-disc">
            Our staff is trained to handle physician’s sample/ expiry defacing
            destruction activity.
          </li>
          <li className="list-disc">
            Lowest error ratio of complaints to shipments through POD tracking
            for each & every shipment
          </li>
          <li className="list-disc">
            Timely immediate delivery of promotional materials before launch of
            a product or a specific marketing campaign to support the marketing
            needs of our clients.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Material;
