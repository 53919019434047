import React from "react";
import SupplyCard from "../../Components/SupplyCard";

const Services = () => {
  return (
    <div>
      <div>
        {/* Breadcumb */}
        <div className="h-32 bg-gray-800 flex flex-col items-center justify-center text-lg font-semibold text-white">
          <p>
            <a className="hover:underline" href="/">
              {" "}
              Home
            </a>{" "}
            /{" "}
            <a className="hover:underline" href="/about-us/company-overview">
              Company Overview
            </a>
          </p>
        </div>

        {/* Banner */}

        {/* Section 1 */}
        <div class="flex flex-wrap justify-center items-center my-10">
          <div class="w-[500px] p-5">
            <p class="text-xl font-bold text-red-800 mb-3">Company Overview</p>
            <p className="text-justify">
              Ramdas Sales India Pvt.Ltd is a leading pharmaceutical supply
              chain solution provider engaged in C&F, Super Stockist Operations
              with specialization in the Distribution, Logistics, Warehousing,
              and Licensing facilities in Western India.
            </p>
          </div>
          <div className=" bg-red-800 lg:mt-0 mt-4 rounded-lg button-pop-in w-[400px]">
            <img
              src={require("../asserts/companyover.jpg")}
              className="  rounded-lg lg:pt-2 lg:pr-2 lg:hover:pr-0 lg:hover:pt-0  transition-all ease-in cursor-pointer duration-200"
            />
          </div>
        </div>

        <div className="mb-4">
          <SupplyCard />
        </div>
      </div>
    </div>
  );
};

export default Services;
