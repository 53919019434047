import React, { useRef } from "react";
import Carousel from "react-elastic-carousel";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.css";

const OurClient = () => {
  var settings = {
    dots: true,
    arrows: false,
    // infinite: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768, // Defines the maximum screen width for the setting
        settings: {
          slidesToShow: 1, // Adjusts the number of slides to show on screens smaller than 768px
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3, // Adjusts for tablets or small desktops
          slidesToScroll: 1,
          // infinite: true,
          dots: true,
        },
      },
      // You can add more breakpoints and settings here as needed
    ],
  };

  const handleHover = (event) => {
    event.target.style.transform = "scale(1.05)";
  };

  const handleLeave = (event) => {
    event.target.style.transform = "scale(1)";
  };
  const additionalImagesRef = useRef(null);

  const handleViewMoreClick = () => {
    additionalImagesRef.current.scrollIntoView({ behavior: "smooth" });
  };
  // const breakPoints = [
  //   { enableAutoPlay: false, autoPlaySpeed: 1000, itemsToShow: 2 },
  //   { enableAutoPlay: false, autoPlaySpeed: 1000, itemsToShow: 2 },
  //   { enableAutoPlay: false, autoPlaySpeed: 1000, itemsToShow: 2 },
  //   { enableAutoPlay: false, autoPlaySpeed: 1000, itemsToShow: 2 },
  // ];
  return (
    <div>
      {" "}
      {/* Breadcumb */}
      <div className="h-32 bg-gray-800 flex flex-col items-center justify-center text-lg font-semibold text-white">
        <p>
          <a className="hover:underline" href="/">
            {" "}
            Home
          </a>{" "}
          /{" "}
          <a className="hover:underline" href="/our-clients">
            Our Clients
          </a>
        </p>
      </div>
      {/* clients logo */}
      <div ref={additionalImagesRef}></div>
      <Slider
        autoplay
        autoplaySpeed={3000}
        infinite={true}
        {...settings}
        className=" flex flex-wrap justify-center  gap-10 mb-20 px-4 button-pop-in py-10"
      >
        <div className="lg:py-10 cursor-pointer">
          <img
            style={{ transition: "transform 0.3s" }}
            onMouseEnter={handleHover}
            onMouseLeave={handleLeave}
            src={require("../asserts/glenmark.png")}
            className="shadow-2xl py-14 px-10 lg:w-[200px] w-[350px]"
          />
        </div>
        <div className="lg:py-10 cursor-pointer">
          <img
            style={{ transition: "transform 0.3s" }}
            onMouseEnter={handleHover}
            onMouseLeave={handleLeave}
            src={require("../asserts/himalaya.png")}
            className="shadow-2xl py-14 px-10 lg:w-[200px] w-[350px]"
          />
        </div>

        <div className="lg:py-10 cursor-pointer">
          <img
            style={{ transition: "transform 0.3s" }}
            onMouseEnter={handleHover}
            onMouseLeave={handleLeave}
            src={require("../asserts/dabur.png")}
            className="shadow-2xl py-14 px-10 lg:w-[200px] w-[350px]"
          />
        </div>
        <div className="lg:py-10 cursor-pointer">
          <img
            style={{ transition: "transform 0.3s" }}
            onMouseEnter={handleHover}
            onMouseLeave={handleLeave}
            src={require("../asserts/apex.png")}
            className="shadow-2xl py-14 px-10 lg:w-[200px] w-[350px]"
          />
        </div>
        <div className="lg:py-10 cursor-pointer">
          <img
            style={{ transition: "transform 0.3s" }}
            onMouseEnter={handleHover}
            onMouseLeave={handleLeave}
            src={require("../asserts/sachio.png")}
            className="shadow-2xl py-14 px-10 lg:w-[200px] w-[350px]"
          />
        </div>
        <div className="lg:py-10 cursor-pointer">
          <img
            style={{ transition: "transform 0.3s" }}
            onMouseEnter={handleHover}
            onMouseLeave={handleLeave}
            src={require("../asserts/integrace.png")}
            className="shadow-2xl py-14 px-10 lg:w-[200px] w-[350px]"
          />
        </div>

        <div className="lg:py-10 cursor-pointer">
          <img
            style={{ transition: "transform 0.3s" }}
            onMouseEnter={handleHover}
            onMouseLeave={handleLeave}
            src={require("../asserts/pharmtak.png")}
            className="shadow-2xl py-14 px-10 lg:w-[200px] w-[350px]"
          />
        </div>
        <div className="lg:py-10 cursor-pointer">
          <img
            style={{ transition: "transform 0.3s" }}
            onMouseEnter={handleHover}
            onMouseLeave={handleLeave}
            src={require("../asserts/British.png")}
            className="shadow-2xl py-14 px-10 lg:w-[200px] w-[350px]"
          />
        </div>
        <div className="lg:py-10 cursor-pointer">
          <img
            style={{ transition: "transform 0.3s" }}
            onMouseEnter={handleHover}
            onMouseLeave={handleLeave}
            src={require("../asserts/alniche.png")}
            className="shadow-2xl py-14 px-10 lg:w-[200px] w-[350px]"
          />
        </div>
        <div className="lg:py-10 cursor-pointer">
          <img
            style={{ transition: "transform 0.3s" }}
            onMouseEnter={handleHover}
            onMouseLeave={handleLeave}
            src={require("../asserts/eiser.png")}
            className="shadow-2xl py-14 px-10 lg:w-[200px] w-[350px]"
          />
        </div>
      </Slider>
    </div>
  );
};

export default OurClient;
