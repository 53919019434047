import React, { useEffect, useRef, useState } from "react";
import HeroImage from "../asserts/backgroundbg.png";
import "./style.css";

const OurJourney = () => {
  const additionalImagesRef = useRef(null);

  const handleViewMoreClick = () => {
    additionalImagesRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const [isAnimated, setIsAnimated] = useState(false);

  useEffect(() => {
    const scrollAnimationElements =
      document.querySelectorAll(".scroll-animation");

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("active");
          observer.unobserve(entry.target);
          setIsAnimated(true); // Update state to indicate animation completion
        }
      });
    });

    scrollAnimationElements.forEach((element) => {
      observer.observe(element);
    });

    // Cleanup the observer when the component unmounts
    return () => {
      observer.disconnect();
    };
  }, []); // Empty dependency array ensures useEffect runs only once on mount
  const containerStyle = {
    background: "url(" + HeroImage + ") center/cover",
    backgroundColor: "#1C242970", // Set your desired background color
  };
  return (
    <div className="" style={containerStyle}>
      {/* Breadcumb */}
      <div className="h-32 bg-gray-800 flex flex-col items-center justify-center text-lg font-semibold text-white">
        <p>
          <a className="hover:underline" href="/">
            {" "}
            Home
          </a>{" "}
          /{" "}
          <a className="hover:underline" href="/about-us/our-journey">
            Our Journey
          </a>
        </p>
      </div>
      {/* Section 1 */}
      <div class="bg-gray-200 flex flex-wrap gap-10 justify-center items-center  p-5  ">
        {/* <div className=" bg-red-800 lg:mt-0 mt-4 rounded-lg button-pop-in w-[400px]">
          <img
            src={require("../asserts/journey.png")}
            className=" h-[300px] rounded-lg lg:pt-2 lg:pr-2 lg:hover:pr-0 lg:hover:pt-0  transition-all ease-in cursor-pointer duration-200"
          />
        </div> */}
        <div class="w-[500px] text-center ">
          <p class="text-xl font-bold text-red-800 mb-3">Our Journey</p>
          <p>
            Founded as Ramdas Pharma Distribution Pvt. Ltd. in 1976 as one of
            the first few stockist and distributors in the Pharmaceutical
            industry, company has established itself as a leading name in Pharma
            industry with a successful journey spanning over 45 years.
          </p>
        </div>
      </div>

      <div ref={additionalImagesRef}></div>
      <div
        className={`py-24 w-full flex justify-center items-center ${
          isAnimated ? "active" : ""
        }`}
      >
        <div className="max-w-7xl mx-auto w-full grid grid-cols-9 px-2 ">
          {/* <!-- Stack 1 --> */}
          <div className="col-span-4 w-full h-full border-l-4 border-red-800 rounded-md button-pop-in transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 ">
            <div className="w-full lg:w-[520px] rounded-md h-full bg-gray-200 p-3 lg:p-6 md:pl-7 ">
              <h1 className=" text-xl text-black font-medium py-2">1950</h1>
              <p className="text-black sm:text-sm text-xs">
                Coming to India post partition, our founder Late Mr. Ramchand
                Tulsani started a small medical store KDas & Sons with his
                brothers in Ulhasnagar – a town in Thane district in Western
                Maharashtra, which exits to date.
              </p>
            </div>
          </div>
          <div className="relative col-span-1 w-full h-full flex justify-center items-center">
            <div className="h-full w-1 bg-red-800"></div>
            <div className="absolute w-6 h-6 rounded-full bg-red-800 z-10 text-white text-center">
              1
            </div>
          </div>
          <div className="col-span-4 w-full h-full"></div>

          {/* <!-- Stack 2 --> */}
          <div className="col-span-4 w-full h-full"></div>
          <div className="relative col-span-1 w-full h-full flex justify-center items-center">
            <div className="h-full w-1 bg-red-800"></div>
            <div className="absolute w-6 h-6 rounded-full bg-red-800 z-10 text-white text-center">
              2
            </div>
          </div>
          <div className="col-span-4 w-full h-full border-r-4 border-red-800 rounded-md button-pop-in transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300 ">
            <div className="w-full lg:ml-8  rounded-md h-full bg-gray-200 p-3 lg:p-6 md:pl-4">
              <h1 className="text-black text-xl font-medium py-2">1952</h1>
              <p className="text-black sm:text-sm text-xs">
                Mr. Ramchand Tulsani became one of the founder members of the
                Pharma Chemists and Drugist’s Association.
              </p>
            </div>
          </div>

          {/* <!-- Stack 3 --> */}
          <div className="col-span-4 w-full h-full border-l-4 border-red-800 rounded-md button-pop-in transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300 ">
            <div className="w-full lg:w-[520px] h-full rounded-md bg-gray-200  p-3 lg:p-6 md:pl-4">
              <h1 className="text-black text-xl font-medium py-2">
                1960 - 1970
              </h1>
              <p className="text-black sm:text-sm text-xs">
                Creating a name for themselves in the pharmaceutical retail
                trade business, they went on to establish their foothold in the
                wholesale business.
              </p>
            </div>
          </div>
          <div className="relative col-span-1 w-full h-full flex justify-center items-center">
            <div className="h-full w-1 bg-red-800"></div>
            <div className="absolute w-6 h-6 rounded-full bg-red-800 z-10 text-white text-center">
              3
            </div>
          </div>
          <div className="col-span-4 w-full h-full"></div>

          {/* <!-- Stack 4 --> */}
          <div className="col-span-4 w-full h-full"></div>
          <div className="relative col-span-1 w-full h-full flex justify-center items-center ">
            <div className="h-full w-1 bg-red-800 pr-"></div>
            <div className="absolute w-6 h-6 rounded-full bg-red-800 z-10 text-white text-center">
              4
            </div>
          </div>
          <div className="col-span-4 w-full h-full border-r-4 border-red-800 rounded-md button-pop-in transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300 ">
            <div className="w-full lg:ml-8  rounded-md h-full bg-gray-200  p-3 lg:p-6 md:pl-4">
              <h1 className="text-black text-xl font-medium py-2">1976</h1>
              <p className="text-black sm:text-sm text-xs">
                Inception of Ramdas Pharma Distributors was done by Mr. Ramchand
                Tulsani, It was one of the first stockiest established in Shahad
                town in Thane to cater medicines to chemists in Ulhasnagar,
                Kalyan and other cities in Thane district.
              </p>
            </div>
          </div>

          {/* <!-- Stack 5 --> */}
          <div className="col-span-4 w-full h-full border-l-4 border-red-800 rounded-md button-pop-in transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300  ">
            <div className="w-full lg:w-[520px] rounded-md h-full bg-gray-200  p-3 lg:p-6 md:pl-4">
              <h1 className="text-black text-xl font-medium py-2">1977</h1>
              <p className="text-black sm:text-sm text-xs">
                Ramdas Pharma Distributors Pvt. Ltd became the first stockiest
                for Glenmark Pharmaceuticals Ltd globally.
              </p>
            </div>
          </div>
          <div className="relative col-span-1 w-full h-full flex justify-center items-center">
            <div className="h-full w-1 bg-red-800"></div>
            <div className="absolute w-6 h-6 rounded-full bg-red-800 z-10 text-white text-center">
              5
            </div>
          </div>
          <div className="col-span-4 w-full h-full"></div>

          {/* <!-- Stack 6 --> */}
          <div className="col-span-4 w-full h-full"></div>
          <div className="relative col-span-1 w-full h-full flex justify-center items-center ">
            <div className="h-full w-1 bg-red-800 pr-"></div>
            <div className="absolute w-6 h-6 rounded-full bg-red-800 z-10 text-white text-center">
              6
            </div>
          </div>
          <div className="col-span-4 w-full h-full border-r-4 border-red-800 rounded-md button-pop-in transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300 ">
            <div className="w-full lg:ml-8  rounded-md h-full bg-gray-200  p-3 lg:p-6 md:pl-4">
              <h1 className="text-black text-xl font-medium py-2">
                1980 - 1990{" "}
              </h1>
              <p className="text-black sm:text-sm text-xs">
                Ramdas Pharma Distributors Pvt. Ltd became an authorized
                distributor for around more than 80 companies with new leaders
                in the business Late Mr. Khial Tulsani and Mr. Girdhari Tulsani
                (sons of Mr. Ramchand Tulsani)
              </p>
            </div>
          </div>

          {/* <!-- Stack 7 --> */}
          <div className="col-span-4 w-full h-full border-l-4 border-red-800 rounded-md button-pop-in transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300 ">
            <div className="w-full lg:w-[520px] rounded-md h-full bg-gray-200  p-3 lg:p-6 md:pl-4">
              <h1 className="text-black text-xl font-medium py-2">
                1990 - 1995
              </h1>
              <p className="text-black sm:text-sm text-xs">
                Moving from catering in Thane district, we spread our wings to
                Mumbai to obtain new opportunities in a then highly developing
                pharmaceutical industry.
              </p>
            </div>
          </div>
          <div className="relative col-span-1 w-full h-full flex justify-center items-center">
            <div className="h-full w-1 bg-red-800"></div>
            <div className="absolute w-6 h-6 rounded-full bg-red-800 z-10 text-white text-center">
              7
            </div>
          </div>
          <div className="col-span-4 w-full h-full"></div>

          {/* <!-- Stack 8 --> */}
          <div className="col-span-4 w-full h-full"></div>
          <div className="relative col-span-1 w-full h-full flex justify-center items-center ">
            <div className="h-full w-1 bg-red-800 pr-"></div>
            <div className="absolute w-6 h-6 rounded-full bg-red-800 z-10 text-white text-center">
              8
            </div>
          </div>
          <div className="col-span-4 w-full h-full border-r-4 border-red-800 rounded-md button-pop-in transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300 ">
            <div className="w-full lg:ml-8  rounded-md h-full bg-gray-200  p-3 lg:p-6 md:pl-4">
              <h1 className="text-black text-xl font-medium py-2">1996 </h1>
              <p className="text-black sm:text-sm text-xs">
                A foundation of Ramdas Sales Corporation was laid down by Late
                Mr. Khial Tulsani and Mr. Girdhari Tulsani. Ramdas Sales
                Corporation became the first Super Stockist for Glenmark
                Pharmaceuticals Ltd.
              </p>
            </div>
          </div>

          {/* <!-- Stack 9 --> */}
          <div className="col-span-4 w-full h-full border-l-4 border-red-800 rounded-md button-pop-in transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300  ">
            <div className="w-full lg:w-[520px] rounded-md h-full bg-gray-200  p-3 lg:p-6 md:pl-4">
              <h1 className="text-black text-xl font-medium py-2">2000s</h1>
              <p className="text-black sm:text-sm text-xs">
                Looking at the business requirements, additional warehousing
                space & office space was acquired & RSC soon made an impact in
                the Supply chain industry.
              </p>
            </div>
          </div>
          <div className="relative col-span-1 w-full h-full flex justify-center items-center">
            <div className="h-full w-1 bg-red-800"></div>
            <div className="absolute w-6 h-6 rounded-full bg-red-800 z-10 text-white text-center">
              9
            </div>
          </div>
          <div className="col-span-4 w-full h-full"></div>

          {/* <!-- Stack 10 --> */}
          <div className="col-span-4 w-full h-full"></div>
          <div className="relative col-span-1 w-full h-full flex justify-center items-center ">
            <div className="h-full w-1 bg-red-800 pr-"></div>
            <div className="absolute w-6 h-6 rounded-full bg-red-800 z-10 text-white text-center">
              10
            </div>
          </div>
          <div className="col-span-4 w-full h-full border-r-4 border-red-800 rounded-md button-pop-in transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300 ">
            <div className="w-full lg:ml-8 rounded-md h-full bg-gray-200  p-3 lg:p-6 md:pl-4">
              <h1 className="text-black text-xl font-medium py-2">
                2005- 2010{" "}
              </h1>
              <p className="text-black sm:text-sm text-xs">
                We saw a significant growth with addition of multiple clientele
                like Himalaya Wellness, Ajanta Pharma and others under the
                strong leadership of its partner’s Mr. Girdhari Tulsani and Mrs.
                Ekta Tulsani.
              </p>
            </div>
          </div>

          {/* <!-- Stack 11 --> */}
          <div className="col-span-4 w-full h-full border-l-4 border-red-800 rounded-md button-pop-in transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300  ">
            <div className="w-full lg:w-[520px] rounded-md h-full bg-gray-200  p-3 lg:p-6 md:pl-4">
              <h1 className="text-black text-xl font-medium py-2">
                2010 - 2019
              </h1>
              <p className="text-black sm:text-sm text-xs">
                Expanding the horizons, RSC catered its supply chain services to
                Goa and Rest of Maharashtra.
              </p>
            </div>
          </div>
          <div className="relative col-span-1 w-full h-full flex justify-center items-center">
            <div className="h-full w-1 bg-red-800"></div>
            <div className="absolute w-6 h-6 rounded-full bg-red-800 z-10 text-white text-center">
              11
            </div>
          </div>
          <div className="col-span-4 w-full h-full"></div>

          {/* <!-- Stack 12 --> */}
          <div className="col-span-4 w-full h-full"></div>
          <div className="relative col-span-1 w-full h-full flex justify-center items-center ">
            <div className="h-full w-1 bg-red-800 pr-"></div>
            <div className="absolute w-6 h-6 rounded-full bg-red-800 z-10 text-white text-center">
              12
            </div>
          </div>
          <div className="col-span-4 w-full h-full border-r-4 border-red-800 rounded-md button-pop-in transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300 ">
            <div className="w-full lg:ml-8 rounded-md h-full bg-gray-200  p-3 lg:p-6 md:pl-4">
              <h1 className="text-black text-xl font-medium py-2">
                2020- 2022{" "}
              </h1>
              <p className="text-black sm:text-sm text-xs">
                To cater the industry growing needs, we expanded our warehousing
                facilities, digitized many operations and currently operate in
                area of 175000 sq ft.
              </p>
            </div>
          </div>
          {/* <!-- Stack 13 --> */}
          <div className="col-span-4 w-full h-full border-l-4 border-red-800 rounded-md button-pop-in transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300 ">
            <div className="w-full lg:w-[520px] rounded-md h-full bg-gray-200  p-3 lg:p-6 md:pl-4">
              <h1 className="text-black text-xl font-medium py-2">2022</h1>
              <p className="text-black sm:text-sm text-xs">
                Our Director’s daughter Dr. Sneha Tulsani, the 3rd generation;
                after her career of 10 years in dental space stepped into the
                business and is currently inheriting the values of business
                conduct and looking into execution of our vision to serve all
                India Pharma distribution needs.
              </p>
            </div>
          </div>
          <div className="relative col-span-1 w-full h-full flex justify-center items-center">
            <div className="h-full w-1 bg-red-800"></div>
            <div className="absolute w-6 h-6 rounded-full bg-red-800 z-10 text-white text-center">
              13
            </div>
          </div>
          <div className="col-span-4 w-full h-full"></div>

          {/* <!-- Stack 14 --> */}
          {/* <div className="col-span-4 w-full h-full"></div>
          <div className="relative col-span-1 w-full h-full flex justify-center items-center ">
            <div className="h-full w-1 bg-red-800 pr-"></div>
            <div className="absolute w-6 h-6 rounded-full bg-red-800 z-10 text-white text-center">
              12
            </div>
          </div>
          <div className="col-span-4 w-full h-full border-r-4 border-red-800 rounded-md button-pop-in transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300 ">
            <div className="w-full lg:ml-8 rounded-md h-full bg-gray-200  p-3 lg:p-6 md:pl-4">
              <h1 className="text-black text-xl font-medium py-2">
                2024 & upwards
              </h1>
              <p className="text-black sm:text-sm text-xs">
                With the new energy and old experience RSC becomes a Pvt. Ltd
                company Ramdas Sales India Pvt.Ltd and we start our wherehouse
                operation in a new city in pune.
              </p>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default OurJourney;
