import React from "react";

const ColdChain = () => {
  return (
    <div>
      <div className="shadow-2xl lg:w-[800px] m-auto px-10 py-20 space-y-4 text-justify ">
        <h2 className="text-2xl font-semibold">Cold Chain Distribution</h2>
        <p className="">
          Cold Chain distribution forms a critical part of the pharmaceutical
          supply chain. At RSIPL, we carry out cold chain distribution adhering
          to utmost quality assurance throughout storage and transit.
        </p>
        <p className="text-lg font-semibold">
          {" "}
          Key features of our Cold Chain operations include:
        </p>

        <ul className="space-y-4">
          <li className="list-disc">
            <span className="font-bold">Infrastructure:</span> Deep freezers and
            cold chain rooms of approximately 500-600 sqft in size and 24 hour
            back up facility to ensure no failure of electricity.
          </li>
          <li className="list-disc">
            <span className="font-bold">
              Temperature Monitoring & Control:{" "}
            </span>
            Tracking of temperature conditions in real time, Temperature
            controlled packaging, quick deliveries in 24 hours to outstations.
          </li>
          <li className="list-disc">
            <span className="font-bold">Regulatory compliances: </span>
            Regulatory compliances followed with respect to Drug laws and FDA.
          </li>
          <li className="list-disc">
            <span className="font-bold">Extensive: </span>
            Extensive hygiene and stringent quality assurance.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ColdChain;
