import { combineReducers } from "redux";
// import CricketReducer from "./CricketApi/reducers";
// import LoadingReducer from "../Redux copy/Loading/reducers";
import MenuReducer from "../Redux/MenuToggle/reducers";

export default combineReducers({
  // CricketReducer,
  // LoadingReducer,
  MenuReducer,
});
