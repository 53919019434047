import React, { useState } from "react";
import { ArrowDown, ArrowUp, Logo } from "../../Icons";
import { HeaderRoutes } from "./HeaderRoutes";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Hamburger from "hamburger-react";
import { toggleMenu } from "../../Redux/MenuToggle/actions";
import { AnimatePresence, motion } from "framer-motion";

const Header = () => {
  const dispatch = useDispatch();
  const { isMenuOpen } = useSelector((state) => state.MenuReducer);
  const [openSubMenu, setOpenSubMenu] = useState(null);

  const handleToggle = (e) => {
    e.preventDefault();

    dispatch(toggleMenu(!isMenuOpen));
    setOpenSubMenu(null);
  };
  // Close the menu when a menu item is clicked
  const handleMenuItemClick = () => {
    if (isMenuOpen) {
      dispatch(toggleMenu(false));
    }
  };

  const handleSubMenuToggle = (idx) => {
    setOpenSubMenu(openSubMenu === idx ? null : idx);
  };

  const SubMenu = ({ subpages }) => (
    <ul className="grid gap-2">
      {subpages.map((subpage) => (
        <li
          key={subpage.title}
          className="w-full p-[0.08rem] rounded-xl bg-gradient-to-tr"
        >
          <Link
            to={subpage.href}
            className="flex items-center justify-between w-full p-3 text-black rounded-xl bg-[#fff]"
            onClick={handleMenuItemClick}
          >
            <span className="flex gap-1 text-lg">{subpage.title}</span>
          </Link>
        </li>
      ))}
    </ul>
  );

  return (
    <nav>
      <div className="flex flex-wrap justify-between items-center md:px-20 px-2 py-3 shadow-md sticky z-10">
        <div className="flex items-center">
          <a href="/">
            <Logo className="w-16" />
          </a>
          <h2 className="uppercase font-semibold text-red-800">
            <a className="text-[15px] lg:text-[20px] " href="/">
              {" "}
              Ramdas Sales India Pvt. Ltd.
            </a>
          </h2>
        </div>
        <div className="flex justify- items-center ">
          <div className=" flex items-center text-2xl lg:hidden">
            <a className="" href="#" onClick={handleToggle}>
              <Hamburger toggled={isMenuOpen} size={25} />
            </a>
          </div>
          <div className="flex justify-between items-center py-2">
            <ul className="hidden lg:flex lg:items-center md:gap-2 md:flex space-x-5 flex-wrap justify-between text-[#003152]  font-semibold ">
              {HeaderRoutes.map((route) => {
                const { title, href, subpages } = route;
                return (
                  <li className="group relative " key={(title, href)}>
                    {subpages ? (
                      <>
                        <a href={href} className="hover:text-[#003152]">
                          {title}
                        </a>
                        <ul className="absolute -left-6 mt-2 space-y-2 bg-white border  invisible group-hover:opacity-100 group-hover:visible transition-all duration-300 delay-100">
                          {subpages.map((subpage) => (
                            <li
                              key={subpage.title}
                              className="hover:bg-[#A2272E] w-[250px] text-gray-800 hover:text-white px-6"
                            >
                              <Link
                                to={subpage.href}
                                className="block hover:text-[#ffffff]  py-2  "
                              >
                                {subpage.title}
                              </Link>
                            </li>
                          ))}
                          <span className="absolute bottom-0 left-0 w-0 bg-[#A2272E] h-0.5 group-hover:w-full transition-all duration-300"></span>
                        </ul>
                      </>
                    ) : (
                      <a href={href} className="hover:text-[#003152]">
                        {title}
                      </a>
                    )}
                    <span className="absolute bottom-0 left-0 w-0 bg-[#A2272E] h-0.5 group-hover:w-full transition-all duration-300"></span>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
      {/* Mobile Nav Menu */}
      <div className="lg:hidden ">
        <AnimatePresence>
          {isMenuOpen && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
              className="z-10 left-0 shadow-4xl right-0  p-5 pt-0 bg-white border-b border-b-white/20"
            >
              <ul className="grid gap-2">
                {HeaderRoutes.map((route, idx) => (
                  <motion.li
                    initial={{ scale: 0, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{
                      type: "spring",
                      stiffness: 260,
                      damping: 20,
                      delay: 0.1 + idx / 10,
                    }}
                    key={route.title}
                    className="w-full p-[0.08rem] rounded-xl bg-gradient-to-tr"
                  >
                    {route.subpages ? (
                      <>
                        <a
                          className={
                            "flex items-center justify-between w-full p-3 text-black rounded-xl bg-[#fff]"
                          }
                          href={route.href}
                        >
                          <span className="flex gap-1 text-lg">
                            {route.title}
                          </span>
                          <span
                            onClick={() => handleSubMenuToggle(idx)}
                            className="bg-white rounded-full"
                          >
                            {openSubMenu === idx ? <ArrowDown /> : <ArrowUp />}
                          </span>
                        </a>
                        {openSubMenu === idx && (
                          <SubMenu subpages={route.subpages} />
                        )}
                      </>
                    ) : (
                      <Link
                        to={route.href}
                        className={
                          "flex items-center justify-between w-full p-3 text-black rounded-xl bg-[#fff]"
                        }
                        onClick={handleMenuItemClick}
                      >
                        <span className="flex gap-1 text-lg">
                          {route.title}
                        </span>
                      </Link>
                    )}
                  </motion.li>
                ))}
              </ul>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </nav>
  );
};

export default Header;
