import React from "react";

const Carrying = () => {
  return (
    <div>
      <div className="shadow-2xl lg:w-[800px] m-auto px-10 py-20 space-y-4 text-justify ">
        <h2 className="text-2xl font-semibold">
          Carrying & Forwarding Agent Services
        </h2>
        <p>
          Our team efficiently handles CFA operations for Pharmaceutical and
          FMCG companies for West India. We handle over 7000+ invoices and
          dispatch approximately gross volume of 1 lakh shippers monthly across
          India.
        </p>
        <p className="text-lg font-semibold">
          Key features of our CFA operations:
        </p>

        <ul className="space-y-4">
          <li className="list-disc">
            <span className="font-bold">Warehousing and Storage: </span>
            Temperature-controlled facilities to preserve the efficacy of
            pharmaceutical products. We adhere to stringent quality standards to
            safeguard the integrity of goods throughout the storage period.
          </li>
          <li className="list-disc">
            <span className="font-bold">Inventory Management: </span>
            Along with our clients, we constantly monitor stock levels,
            providing information, facilitating timely replenishment and
            optimization of inventory turnover. Monthly stock checks are
            performed to ensure inventory hygiene and provide real time picture.
          </li>
          <li className="list-disc">
            <span className="font-bold"> Order Fulfilment: </span>
            Prompt daily order processing and fulfilment services to ensure
            timely delivery of pharmaceutical products to stockists &
            institutions. Timely payment collection, coordination with field
            force and up to date accounting helps us enhance customer
            experience.
          </li>
          <li className="list-disc">
            <span className="font-bold">Transportation and Distribution: </span>
            With a robust distribution network, and our established connections
            with our transport vendors, we ensure swift and secure
            transportation of pharmaceutical goods to their intended
            destinations. 24 hours delivery is facilitated for local
            destinations and 72 hours outstation deliveries.
          </li>
          <li className="list-disc">
            <span className="font-bold">
              {" "}
              Regulatory Compliance & Documentation:{" "}
            </span>
            We adhere to all regulatory guidelines and quality standards
            governing the pharmaceutical industry. Our team stays updated with
            the latest regulatory requirements to ensure compliance at every
            stage of the supply chain.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Carrying;
