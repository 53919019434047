import React from "react";

const OurStrengths = () => {
  return (
    <div>
      {" "}
      {/* Breadcumb */}
      <div className="h-32 bg-gray-800 flex flex-col items-center justify-center text-lg font-semibold text-white">
        <p>
          <a className="hover:underline" href="/">
            {" "}
            Home
          </a>{" "}
          /{" "}
          <a className="hover:underline" href="/about-us/our-strengths">
            Our Strengths
          </a>
        </p>
      </div>
      <div className="flex flex-wrap lg:justify-center lg:items-center lg:gap-20 bg-[#F2F2F2] py-20">
        <div className="px-4 space-y-5">
          <h2 className="lg:text-5xl text-3xl font-bold text-red-800">
            Our Strengths
          </h2>
          <p className="lg:w-[400px] w-[350px]">
            {" "}
            With the experience of over Forty-Five years in the Pharma
            Distribution Network, RSIPL has positioned itself as a market leader
            under the leadership of Mr. Girdhari Tulsani. Dr. Sneha Tulsani
            (CEO), brings a fresh perspective to RSIPL thus creating a perfect
            blend of the old wisdom and the new energy
          </p>
          <h2 className="text-3xl font-bold text-red-800">
            Committed Partners
          </h2>
          <p className="lg:w-[400px] w-[350px]">
            {" "}
            We partner with the best in the business, courier and freight
            companies, who are committed and who resonate with our standards and
            values
          </p>
        </div>
        {/* 1st  */}
        <div className="bg-white shadow-2xl px-4 py-10 rounded-xl h-[600px]">
          <img
            src={require("../asserts/strength.jpg")}
            className="w-[400px] pb-7"
          />
          <h4 className="text-2xl font-bold text-red-800 pb-4">
            {" "}
            Modern Infrastructure
          </h4>
          <ul className="flex flex-col gap-2" l>
            <li>
              <i className="fa-solid fa-circle text-red-800 text-sm mr-2"></i>{" "}
              1,60,000+ sq. ft. Warehousing Facilities{" "}
            </li>
            <li>
              <i className="fa-solid fa-circle text-red-800 text-sm mr-2"></i>{" "}
              AC Rooms for Stock Storage{" "}
            </li>
            <li>
              <i className="fa-solid fa-circle text-red-800 text-sm mr-2"></i>{" "}
              Cold Room Storage Facilities (2°C - 8°C)
            </li>
            <li>
              <i className="fa-solid fa-circle text-red-800 text-sm mr-2"></i>{" "}
              Deep Freezer Facilities (-15°C){" "}
            </li>
            <li>
              <i className="fa-solid fa-circle text-red-800 text-sm mr-2"></i>{" "}
              Online Temperature Monitoring System
            </li>
            <li>
              <i className="fa-solid fa-circle text-red-800 text-sm mr-2"></i>{" "}
              Integrated ERP Software
            </li>
          </ul>
        </div>

        {/* 2nd */}
        <div className="bg-white shadow-2xl px-4 py-10 rounded-xl h-[600px]">
          <img
            src={require("../asserts/strength.jpg")}
            className="w-[400px] pb-7"
          />
          <h4 className="text-2xl font-bold text-red-800 pb-4">
            {" "}
            Dynamic Team
          </h4>
          <ul className="flex flex-col gap-2" l>
            <li>
              <i className="fa-solid fa-circle text-red-800 text-sm mr-2"></i> 8
              Top ManagementTeam
            </li>
            <li>
              <i className="fa-solid fa-circle text-red-800 text-sm mr-2"></i>{" "}
              10 Mid-Management Team
            </li>
            <li>
              <i className="fa-solid fa-circle text-red-800 text-sm mr-2"></i>{" "}
              10 Customer Service Executives
            </li>
            <li>
              <i className="fa-solid fa-circle text-red-800 text-sm mr-2"></i>{" "}
              50+ Warehouse Workforce
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default OurStrengths;
