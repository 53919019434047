import React, { useRef, useState } from "react";
import { Careers, Pharma } from "../../Icons";
import "./style.css";
import emailjs from "emailjs-com";

const Career = () => {
  const additionalImagesRef = useRef(null);
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    designation: "",
    dob: "",
    experience: "",
    resume: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();

    if (
      !formData.name ||
      !formData.email ||
      !formData.phone ||
      !formData.designation ||
      !formData.dob ||
      !formData.experience ||
      !formData.resume ||
      !isChecked
    ) {
      setErrorMessage("Please fill in all required fields.");
      return;
    }

    setIsLoading(true);
    setErrorMessage(""); // Clear any existing error messages.

    try {
      // Assuming you have formData set up to capture your form data correctly
      const templateParams = {
        from_name: formData.name,
        from_email: formData.email,
        from_phone: formData.phone,
        from_designation: formData.designation,
        from_dob: formData.dob,
        from_experience: formData.experience,
        from_resume: formData.resume,
      };

      await emailjs.send(
        "service_1insjsn",
        "template_xykk5cb",
        templateParams,
        "kcZE7X1RdgXi5EwDW"
      );

      setSuccessMessage("Your message has been sent successfully!");
      // Reset the form if needed
      setFormData({
        name: "",
        email: "",
        phone: "",
        designation: "",
        dob: "",
        experience: "",
        resume: "",
      });
      setIsChecked(false); // Reset the checkbox if needed
    } catch (error) {
      console.error("Failed to send message:", error);
      setErrorMessage("Failed to send message. Please try again.");
    }

    setIsLoading(false);
  };
  console.log({ formData });

  const handleViewMoreClick = () => {
    additionalImagesRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="">
      <div className="">
        {/* Breadcumb */}
        <div className="h-32 bg-gray-800 flex flex-col items-center justify-center text-lg font-semibold text-white">
          <p>
            <a className="hover:underline" href="/">
              {" "}
              Home
            </a>{" "}
            /{" "}
            <a className="hover:underline" href="/careers">
              Careers
            </a>
          </p>
        </div>

        <div className="flex flex-wrap gap-10 justify-center items-center  p-5 my-10">
          <div className="bg-red-800 lg:mt-0 mt-4 rounded-lg button-pop-in w-[500px]">
            <img
              src={require("../asserts/career.jpg")}
              className="rounded-lg  border-t-4 border-red-800"
            />
          </div>
          <div className="w-[500px]">
            <div className="py-6  text-gray-800">
              <h2 className="font-bold lg:text-4xl text-2xl pb-6 ">Careers</h2>
              <p className="text-justify">
                As a leading provider of service solutions in the pharmaceutical
                industry, we play a critical role in ensuring the safe and
                efficient delivery of life-saving medications to patients. At
                Ramdas Sales India Pvt.Ltd, we believe in fostering a dynamic
                work environment where employees can thrive and grow. We
                appreciate employees who bring in fresh ideas and are aligned
                with company’s goals. Whether you are an experienced
                professional or just starting your career, we offer a range of
                opportunities across various departments. We are committed to
                investing in our employees' development and providing ongoing
                training and support to help them succeed. Contact us below to
                join us in our mission to deliver excellence in logistics and
                healthcare.
              </p>
            </div>
            {/* <div className=" flex flex-row gap-x-4 ">
              <button
                onClick={handleViewMoreClick}
                className="bg-red-800 text-white px-5 py-3 transition-transform hover:scale-105 rounded-lg "
              >
                View More
                <i class="fa-solid fa-arrow-down ml-4"></i>
              </button>
            </div> */}
          </div>
        </div>

        {/* Form section */}
        <div ref={additionalImagesRef}></div>
        <div className="flex flex-wrap  justify-center items-center lg:gap-20 py-20 bg-gray-200">
          <div className="w-[500px] bg-white p-4">
            <div className="text-center pb-10">
              <h4 className="lg:text-xl text-lg font-semibold text-gray-800 ">
                WORK WITH US
              </h4>
              <h3 className="lg:text-2xl text-xl font-bold text-red-800">
                JOIN RAMDAS SALES INDIA PVT. LTD.
              </h3>
            </div>
            {/* Form */}
            <form className="form space-y-8 " onSubmit={handleSendMessage}>
              <div className="mb-4 w-full">
                <div className="relative ">
                  <input
                    placeholder="Your name "
                    type="text"
                    id="name"
                    name="name"
                    onChange={handleInputChange}
                    className=" border-gray-300 w-full border  px-6 py-2"
                  />
                  <i className="fa-solid fa-user absolute right-4 top-3 text-gray-400"></i>
                </div>
              </div>
              <div className="mb-4 ">
                {" "}
                <div className="relative">
                  <input
                    placeholder="Your email address"
                    type="email"
                    id="email"
                    name="email"
                    onChange={handleInputChange}
                    className="border-gray-300 w-full border  px-6 py-2 "
                  />
                  <i className="fa-regular fa-envelope absolute right-3 top-3 text-gray-400"></i>
                </div>
              </div>

              <div className="mb-4 ">
                <div className="relative">
                  <input
                    placeholder="Phone number"
                    type="number"
                    id="number"
                    name="phone"
                    onChange={handleInputChange}
                    className="border-gray-300 w-full border px-6 py-2   "
                  />
                  <i className="fa-solid fa-square-arrow-up-right absolute right-3 top-3 text-gray-400"></i>
                </div>
              </div>

              <div className="mb-4">
                <div className="relative">
                  <input
                    placeholder="Upload Resume"
                    type="file"
                    id="resume"
                    name="resume"
                    onChange={handleInputChange}
                    className="border-gray-300 w-full border px-6 py-2"
                  />
                  <i className="fa-solid fa-file-alt absolute right-3 top-3 text-gray-400"></i>
                </div>
              </div>

              <div className="flex flex-wrap justify-center items-center ">
                <button
                  type="submit"
                  className={`bg-red-800 text-white px-5 py-3 mt-5 rounded-lg flex justify-center items-center ${
                    isLoading ? "" : "hover:scale-105"
                  }`}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <>
                      <div className="button-loader"></div>
                      {/* <span className="ml-2">Sending...</span> */}
                    </>
                  ) : (
                    "Send Message"
                  )}
                </button>
                {successMessage && (
                  <div className="mt-3 text-green-500 text-center">
                    {successMessage}
                  </div>
                )}
              </div>
            </form>
          </div>
          <div className="bg-red-800 lg:mt-0 mt-4 rounded-lg button-pop-in w-[400px]">
            <img
              className=" rounded-lg lg:pt-2 lg:pr-2 lg:hover:pr-0 lg:hover:pt-0  transition-all ease-in cursor-pointer duration-200 "
              src={require("../asserts/companyover.jpg")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Career;
