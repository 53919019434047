import React from "react";

const Super = () => {
  return (
    <div>
      <div className="shadow-2xl lg:w-[800px] m-auto px-10 py-20 space-y-4 text-justify ">
        <h2 className="text-2xl font-semibold">
          Super Stockist/Super Distributor Operations
        </h2>
        <p>
          RSIPL is regional, state-wide stockist/distributor for well-known
          pharmaceutical companies. As a super stockist, we play a pivotal role
          in the supply chain eco system acting as key intermediaries between
          manufacturer and their end customers.
        </p>
        <p className="text-lg font-semibold">
          Key features of our Super Stockist operations include:
        </p>

        <ul className="space-y-4">
          <li className="list-disc">
            <span className="list-disc">
              Warehouse and storage facility catered to client’s requirements.{" "}
            </span>
          </li>
          <li className="list-disc">
            <span className="">Quality Assurance</span>
          </li>
          <li className="list-disc">
            <span className="">
              Stock/inventory ownership to keep the smooth functioning of
              super-stockist activities.
            </span>
          </li>
          <li className="list-disc">
            <span className="">
              Regular Stock audits and inventory management.
            </span>
          </li>
          <li className="list-disc">
            <span className="">
              Regulatory compliances by maintaining drug license records/ FDA
              records as per Indian regulatory authorities.
            </span>
          </li>
          <li className="list-disc">
            <span className="">
              End-to-end solution for order execution, dispatch, credit
              management & payment collection.
            </span>
          </li>
          <li className="list-disc">
            <span className="">Efficient return goods management system.</span>
          </li>
          <li className="list-disc">
            <span className="">
              Pharmaceutical industry requires urgent supply of drugs at times.
              Our team efficiently collaborates with courier services to deliver
              lifesaving drugs on urgent requirements.
            </span>
          </li>
          <li className="list-disc">
            <span className="">
              Continuous feedback and coordination with field force and
              distributors to ensure highest customer satisfaction.
            </span>
          </li>
          <li className="list-disc">
            <span className="">Pan India Supply points.</span>
          </li>
          <li className="list-disc">
            <span className="">
              Relationship Management with principal company and distributors,
              retailer as well government bodies to ensure smooth delivery and
              supply.
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Super;
