export const HeaderRoutes = [
  {
    title: "Home",
    href: "/",
  },
  {
    title: "About Us",
    href: "#",
    subpages: [
      {
        title: "Company Overview",
        href: "/about-us/company-overview",
      },
      {
        title: "Leadership",
        href: "/about-us/leadership",
      },
      // {
      //   title: "Our Strengths",
      //   href: "/about-us/our-strengths",
      // },
      {
        title: "Vision  Mission",
        href: "/about-us/vision-mission",
      },
      {
        title: "Awards & Accolades",
        href: "/about-us/awards-accolades",
      },
      {
        title: "Our Journey",
        href: "/about-us/our-journey",
      },
      // Add more subpages as needed
    ],
  },
  {
    title: "Services",
    href: "/services",
  },
  {
    title: "Clientele",
    href: "/clientele",
  },
  {
    title: "Infrastructure",
    href: "infrastructure",
  },
  {
    title: "Careers",
    href: "/careers",
  },
  // {
  //   title: "Insights",
  //   href: "/insights",
  // },
  {
    title: "Contact Us",
    href: "/contact-us",
  },
];
