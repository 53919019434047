import React, { useState } from "react";
import "./item";
import "./style.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import bannerImg from "../../Pages/asserts/infra9.jpg";
import bannerImg1 from "../../Pages/asserts/infra3.jpg";
import bannerImg2 from "../../Pages/asserts/companyoverviewbanner.jpeg";
import Carousel from "react-elastic-carousel";
import SupplyCard from "../../Components/SupplyCard";
import ServicesCard from "../../Components/ServicesCard";
const Home = () => {
  const bgportfolio = {
    backgroundImage: `url(${bannerImg})`,
  };
  const bgportfolio1 = {
    backgroundImage: `url(${bannerImg1})`,
  };
  const bgportfolio2 = {
    backgroundImage: `url(${bannerImg2})`,
  };
  const breakPoints = [
    // { itemsToShow: 1 },
    // { itemsToShow: 1 },
    // { itemsToShow: 1 },
    // { itemsToShow: 1 },
  ];

  const [isOpneBtn, setIsOpenBtn] = useState(false);
  const handleIsOpenBtn = () => {
    setIsOpenBtn(!isOpneBtn);
  };

  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    // speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="hero w-full">
      <Slider autoplay autoplaySpeed={3000} infinite={true} {...settings}>
        {/* 1st */}
        <div>
          <div
            style={bgportfolio2}
            className="w-full h-[500px] bg-cover relative  bg-center"
          >
            <div className="absolute inset-0 bg-black bg-opacity-60"></div>
            <div className="flex flex-col justify-center items-center h-full z-10">
              <h1 className="text-white lg:text-[60px] z-0  text-center leading-[1]">
                {" "}
                Reliable, Sustainable, Customer Centric
              </h1>
              <p className="text-white lg:pt-10 text-lg lg:w-[600px] w-[300px] text-center z-0">
                Leading Pharmaceutical Supply Chain solutions provider in
                Western India.
              </p>

              <p className="custom-btn  bg-[#991B1B] px-16 py-3 mt-4 hover:bg-black  transition duration-300 ease-in-out">
                <a
                  href="/contact-us"
                  className=" text-white hover:text-white font-semibold  "
                >
                  Contact Us
                </a>
              </p>
            </div>
          </div>
        </div>
        <div>
          <div
            style={bgportfolio}
            className="w-full h-[500px] bg-cover  bg-center relative bg-no-repeat "
          >
            <div className="absolute inset-0 bg-black bg-opacity-60 "></div>
            <div className="flex flex-col justify-center items-center h-full z-10">
              <h1 className="text-white lg:text-[60px] z-0 text-center leading-[1] ">
                {" "}
                Reliable, Sustainable, Customer Centric
              </h1>
              <p className="text-white lg:pt-10 text-lg lg:w-[600px] w-[300px] text-center z-0">
                Leading Pharmaceutical Supply Chain solutions provider in
                Western India.
              </p>

              <p className="custom-btn  bg-[#991B1B] px-16 py-3 mt-4 hover:bg-black  transition duration-300 ease-in-out">
                <a
                  href="/about-us/company-overview"
                  className=" text-white hover:text-white  font-semibold  "
                >
                  About Us
                </a>
              </p>
            </div>
          </div>
        </div>
        <div>
          <div
            style={bgportfolio1}
            className="w-full h-[500px] relative bg-cover  bg-center"
          >
            <div className="absolute inset-0 bg-black bg-opacity-60"></div>
            <div className="flex flex-col justify-center items-center h-full z-10">
              <h1 className="text-white lg:text-[60px] z-0 text-center leading-[1]">
                {" "}
                Reliable, Sustainable, Customer Centric
              </h1>
              <p className="text-white lg:pt-10 text-lg lg:w-[600px] w-[300px] text-center z-0">
                Leading Pharmaceutical Supply Chain solutions provider in
                Western India.
              </p>

              <p className="custom-btn  bg-[#991B1B] px-16 py-3 mt-4 hover:bg-black  transition duration-300 ease-in-out">
                <a
                  href="/services"
                  className=" text-white hover:text-white font-semibold  "
                >
                  Our Services
                </a>
              </p>
            </div>
          </div>
        </div>
      </Slider>

      {/* about our company */}
      {/* Section 1 */}
      <div class="flex flex-wrap gap-10 justify-center items-center  p-5 my-10 ">
        <div class="w-[500px] ">
          <p class="text-xl font-bold text-red-800 mb-3">Company Overview</p>
          <p className="text-justify">
            Ramdas Sales India Pvt.Ltd is a leading pharmaceutical supply chain
            solution provider engaged in C&F, Super Stockist Operations with
            specialization in the Distribution, Logistics, Warehousing, and
            Licensing facilities in Western India.
          </p>
        </div>
        <div className=" bg-red-800 lg:mt-0 mt-4 rounded-lg button-pop-in w-[400px]">
          <img
            src={require("../asserts/forbanner.jpeg")}
            className="  rounded-lg lg:pt-2 lg:pr-2 lg:hover:pr-0 lg:hover:pt-0  transition-all ease-in cursor-pointer duration-200 object-cover"
          />
        </div>
      </div>
      {/* Vission Mission */}
      <div className=" flex flex-wrap gap-10 justify-center items-center  p-5 my-10 bg-gray-200">
        <div className="w-[500px]  ">
          <p className="font-bold lg:text-4xl text-2xl pb-4  ">Vision</p>
          <p className=" text-justify">
            RSIPL aims to be a customer - oriented 3PL provider in the
            healthcare industry with dynamic supply chain management solutions,
            with a commitment to excellence and with a moral responsibility to
            all stakeholders.
          </p>
        </div>
        <div className="w-[400px]  ">
          <p className="font-bold lg:text-4xl text-2xl pb-4  ">Mission</p>
          <p className=" text-justify">
            To simplify and modify the distribution logistics across India by
            bringing the best in class supply chain services to Indian
            pharmaceutical market.
          </p>
        </div>
      </div>

      <div className="lg:w-[940px] rounded-[10px] m-auto bg-red-800 px-10 py-10 text-white  flex flex-wrap gap-10 justify-start items-left  p-5 my-10 ">
        <p className=" text-2xl font-bold ">
          Our Values are summarized with acronym for T.E.A.M
        </p>
        <ul className="text-xl flex flex-col gap-3">
          <li>
            <span className="font-bold text-3xl">T</span>ransparency: Building a
            transparent and trustworthy relationship with our clients.
          </li>
          <li>
            <span className="font-bold text-3xl">E</span>fficiency and Empathy:
            Efficient teams along with a culture of respect and empathy.
          </li>
          <li>
            <span className="font-bold text-3xl">A</span>ccountability &
            Agility: To be accountable and agile at every work we do.
          </li>
          <li>
            <span className="font-bold text-3xl">M</span>indfulness: Ensure that
            we are mindful fiscally and socially in our daily operations.
          </li>
        </ul>
      </div>

      {/* SuppyCard */}

      <SupplyCard />
      <ServicesCard />

      <div className="lg:mx-40  bg-red-800 px-10 py-10 text-white flex flex-col gap-10 ">
        <h2 className=" text-2xl font-bold ">Benefits of our services:</h2>
        <ul className="text-xl flex flex-col gap-3">
          <li>
            <span className="font-bold">Reliability:</span> Our proven record of
            accomplishment in pharmaceutical industry underscores our
            reliability and commitment to excellence.
          </li>
          <li>
            <span className="font-bold">Cost Efficiency:</span> By streamlining
            distribution processes, we help our clients reduce operational costs
            and improve overall efficiency.
          </li>
          <li>
            <span className="font-bold">Scalability:</span> Our flexible
            solutions are scalable to accommodate varying demand fluctuations
            and evolving business needs.
          </li>
          <li>
            <span className="font-bold">Safety and Security:</span>
            We prioritize the safety and security of pharmaceutical products
            throughout the supply chain, employing stringent quality control
            measures and security protocols.
          </li>
          <li>
            <span className="font-bold">Customer Satisfaction:</span> Our
            customer-centric approach ensures personalized service and prompt
            resolution of inquiries, fostering long-term partnerships with our
            clients.
          </li>
        </ul>

        <>
          {isOpneBtn ? (
            <>
              <p className="text-xl">
                In the highly regulated and demanding pharmaceutical industry,
                efficient distribution & supply chain operations play a pivotal
                role in ensuring reliability, and compliance. Collaborating with
                a trusted provider like us can streamline your supply chain,
                enhance operational efficiency, and ultimately contribute to
                better healthcare outcomes.{" "}
              </p>
              <p className="text-xl">
                Partner with us to embark on a journey of excellence in
                pharmaceutical distribution, where quality and safety are
                non-negotiable priorities.
              </p>
              {/* <p className="text-xl">
                Reach out to us today to learn more about our specialized
                services and how we can support your pharmaceutical distribution
                needs.
              </p> */}
              <div className=" flex flex-row gap-x-4 ">
                <button
                  onClick={handleIsOpenBtn}
                  className="bg-white text-black font-bold px-5 py-3 transition-transform hover:scale-105 rounded-lg "
                >
                  View Less
                </button>
              </div>
            </>
          ) : (
            <div className=" flex flex-row gap-x-4 ">
              <button
                onClick={handleIsOpenBtn}
                className="bg-white text-black font-bold px-5 py-3 transition-transform hover:scale-105 rounded-lg "
              >
                View More
              </button>
            </div>
          )}
        </>
      </div>

      <div className="button-pop-in">
        <img src={require("../asserts/map.jpg")} />
      </div>
    </div>
  );
};

export default Home;
