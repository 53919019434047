export const FooterThirdRoutes = [
  {
    title: "Our Clients",
    href: "./clientele",
  },
  {
    title: "Services",
    href: "./services",
  },
  {
    title: "Careers",
    href: "./careers",
  },
];
