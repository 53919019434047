import React from "react";

const ServicesCard = () => {
  const handleHover = (event) => {
    event.target.style.transform = "scale(1.05)";
  };

  const handleLeave = (event) => {
    event.target.style.transform = "scale(1)";
  };
  return (
    <div className="mb-10 mt-4 text-center">
      <div className="flex flex-col justify-between items-center  font-semibold lg:px-32 space-y-4 py-20">
        <h2 className="text-4xl text-red-800 font-bold  pb-3 border-b-4 border-red-800 ">
          Our Services
        </h2>
      </div>
      {/* section 1 */}

      <div class="flex flex-wrap justify-center gap-10 w-full items-center">
        <div class="services-block-three w-[380px]">
          <a href="/carrying-&-forwarding-agent-services">
            <div class="pb-3 px-10">
              <img
                src={require("../Pages/asserts/servicseicon1.png")}
                className="w-[100px] h-[95px] m-auto bg-red-800 space-x-4 px-2 py-2 rounded-[10px] hover:transition-transform hover:ease-in "
              />
            </div>
            <h4>Carrying and Forwarding Agent (C & FA)</h4>
            <p class="">
              Our team efficiently handles CFA operations for Pharmaceutical and
              FMCG companies for West India.
            </p>
          </a>
        </div>

        <div class="services-block-three w-[380px]">
          <a href="/super-stockist-super-Distributor-operations">
            <div class="pb-3 px-10">
              <img
                src={require("../Pages/asserts/superdistribution.png")}
                className="w-[100px] h-[95px] m-auto bg-red-800 space-x-4 px-2 py-2 rounded-[10px] hover:transition-transform hover:ease-in "
              />
            </div>
            <h4>Super Distribution</h4>
            <p class="">
              RSIPL is regional, state-wide stockist/distributor for well-known
              pharmaceutical companies.
            </p>
          </a>
        </div>

        <div class="services-block-three w-[380px]">
          <a href="/cold-chain-distribution">
            <div class="pb-3 px-10">
              <img
                src={require("../Pages/asserts/coldchain.png")}
                className="w-[100px] h-[95px] m-auto bg-red-800 space-x-4 px-2 py-2 rounded-[10px] hover:transition-transform hover:ease-in "
              />
            </div>
            <h4>Cold Chain Warehousing and Distribution</h4>
            <p class="">
              Cold Chain distribution forms a critical part of the
              pharmaceutical supply chain.
            </p>
          </a>
        </div>

        {/* <!-- end --> */}
      </div>

      {/* section 2 */}

      <div className="flex flex-wrap justify-center gap-10 w-full items-center">
        <div class="services-block-three w-[380px]">
          <a href="/promotional-material-handling">
            <div class="pb-3 px-10">
              <img
                src={require("../Pages/asserts/Storage, Warehousin.png")}
                className="w-[100px] h-[95px] m-auto bg-red-800 space-x-4 px-2 py-2 rounded-[10px] hover:transition-transform hover:ease-in "
              />
            </div>
            <h4>Promotional Material Handling</h4>
            <p class="">
              Ramdas Sales India Pvt.Ltd provides quality services in the field
              of Promotional Material Handling.
            </p>
          </a>
        </div>

        <div class="services-block-three w-[380px]">
          <a href="/reverse-logistics">
            <div class="pb-3 px-10">
              <img
                src={require("../Pages/asserts/Sample Distribution.png")}
                className="w-[100px] h-[95px] m-auto bg-red-800 space-x-4 px-2 py-2 rounded-[10px] hover:transition-transform hover:ease-in "
              />
            </div>
            <h4>Reverse logistics</h4>
            <p class=" ">
              Reverse logistics in pharmaceutical distribution involves the
              management of returned products..
            </p>
          </a>
        </div>

        <div class="services-block-three w-[380px]">
          <a href="/value-added-services ">
            <div class="pb-3 px-10">
              <img
                src={require("../Pages/asserts/Repacking of Shippers.png")}
                className="w-[100px] h-[95px] m-auto bg-red-800 space-x-4 px-2 py-2 rounded-[10px] hover:transition-transform hover:ease-in "
              />
            </div>
            <h4>Value Added Services</h4>
            <p class=" ">
              In today's dynamic healthcare landscape, we understand the
              critical role that value-added services play..
            </p>
          </a>
        </div>
      </div>

      <div className=" flex flex-row justify-center gap-x-4 ">
        <a
          href="/services"
          className="bg-red-800 text-white hover:text-white  font-bold px-10 py-3 transition-transform hover:scale-105 rounded-[10px] "
        >
          View More
        </a>
      </div>
    </div>
  );
};

export default ServicesCard;
