import React from "react";

const Values = () => {
  return (
    <div>
      <div className="shadow-2xl lg:w-[800px] m-auto px-10 py-20 space-y-4 text-justify ">
        <h2 className="text-2xl font-semibold">Value Added Services</h2>
        <p>
          In today's dynamic healthcare landscape, we understand the critical
          role that value-added services play in optimizing efficiency,
          improving patient outcomes, and ensuring regulatory compliance. Our
          commitment is to provide innovative solutions that go beyond
          traditional distribution, empowering our partners to thrive in an
          increasingly competitive market.
        </p>
        <p>
          We are an extension of your team. We offer dependability & an array of
          services that enable you to focus on their core strength and leave the
          fulfilment expertise to us thus streamlining your final customer
          experience.
        </p>
        <p className="text-lg font-semibold">
          Some of the Value added Services offered –
        </p>

        <ul className="space-y-4">
          <li className="list-disc">
            <span className="">Kitting/De-kitting </span>
          </li>
          <li className="list-disc">
            <span className="">Customised bundling, labelling</span>
          </li>
          <li className="list-disc">
            <span className="">
              {" "}
              Stickering, strapping & other related services.
            </span>
          </li>
          <li className="list-disc">
            <span className="">E-commerce Packaging & Supply </span>
          </li>
          <li className="list-disc">
            <span className=""> Business intelligence</span>
          </li>
        </ul>

        {/* <p className="text-xl font-semibold">Benefits of our services:</p>
        <ul className="space-y-4">
          <li className="list-disc">
            <span className="">Reliability:</span> Our proven record of
            accomplishment in pharmaceutical industry underscores our
            reliability and commitment to excellence.
          </li>
          <li className="list-disc">
            <span className="">Cost Efficiency: </span> Our proven record of By
            streamlining distribution processes, we help our clients reduce
            operational costs and improve overall efficiency.
          </li>
          <li className="list-disc">
            <span className="">Scalability: </span>
            Our flexible solutions are scalable to accommodate varying demand
            fluctuations and evolving business needs.
          </li>
          <li className="list-disc">
            <span className="">Safety and Security: </span>
            We prioritize the safety and security of pharmaceutical products
            throughout the supply chain, employing stringent quality control
            measures and security protocols.
          </li>
          <li className="list-disc">
            <span className=""> Customer Satisfaction: </span>
            Our customer-centric approach ensures personalized service and
            prompt resolution of inquiries, fostering long-term partnerships
            with our clients.
          </li>
        </ul> */}

        {/* <p>
          In the highly regulated and demanding pharmaceutical industry,
          efficient distribution & supply chain operations play a pivotal role
          in ensuring reliability, and compliance. Collaborating with a trusted
          provider like us can streamline your supply chain, enhance operational
          efficiency, and ultimately contribute to better healthcare outcomes.
        </p>
        <p>
          Partner with us to embark on a journey of excellence in pharmaceutical
          distribution, where quality and safety are non-negotiable priorities.
        </p> */}
        <p>
          Reach out to us today to learn more about our specialized services and
          how we can support your pharmaceutical distribution needs.
        </p>
      </div>
    </div>
  );
};

export default Values;
