import React from "react";
import exta from "../asserts/exta.png";
import sneha from "../asserts/sneha.png";
import narendra from "../asserts/narendra.png";
import priya from "../asserts/Priya.png";

import "./style.css";

const Leadership = () => {
  const extabg = {
    backgroundImage: `url(${exta})`,
  };
  const snehabg = {
    backgroundImage: `url(${sneha})`,
  };
  const narendrabg = {
    backgroundImage: `url(${narendra})`,
  };
  const priyabg = {
    backgroundImage: `url(${priya})`,
  };
  return (
    <div>
      {" "}
      {/* Breadcumb */}
      <div className="h-32 bg-gray-800 flex flex-col items-center justify-center text-lg font-semibold text-white">
        <p>
          <a className="hover:underline" href="/">
            {" "}
            Home
          </a>{" "}
          /{" "}
          <a className="hover:underline" href="/leadership">
            Leadership
          </a>
        </p>
      </div>
      {/* Section 1 */}
      <div className="flex flex-wrap gap-10 justify-center items-center  p-5 my-10 ">
        <div className="w-[600px] ">
          <h2 className="font-bold lg:text-4xl text-2xl pb-6">
            Girdhari Tulsani
          </h2>
          <p className="font-semibold text-lg pb-2">Managing Director</p>
          <p className="lg:pr-24 py-2">
            On behalf of the entire team, I welcome you to this virtual platform
            of Ramdas Sales India Pvt.Ltd.
          </p>
          <p className="lg:pr-24 py-2">
            We understand that the success of our logistics operations is deeply
            intertwined with the trust you place in us. We also recognize that
            in today’s rapidly changing global landscape, distribution plays a
            pivotal role in connecting businesses, optimizing supply chains, and
            driving growth. That's why we strive to be your trusted partner,
            offering innovative solutions and unwavering support at every step.
          </p>
          <p className="lg:pr-24 py-2">
            Our commitment to excellence extends beyond our services. We place
            great emphasis on building long-term relationships with our clients,
            based on trust, transparency, and open communication.
          </p>
          <p className="lg:pr-24 py-2">
            We invite you to explore the wide range of services we offer & look
            forward to having you on board with us.
          </p>
        </div>
        <div className=" bg-red-800 lg:p-[2px]  rounded-lg button-pop-in w-[400px]">
          <img
            src={require("../asserts/Mr. Girdhari Tulsani.png")}
            className="rounded-lg lg:hover:h-[450px] lg:h-[450px] lg:w-[500px] transition-all ease-in cursor-pointer duration-200"
          />
        </div>
      </div>
      <div className="flex flex-wrap justify-center items-start gap-10 pb-20 ">
        <div className="text-center">
          <div
            style={extabg}
            className="w-[150px] h-[150px] bg-cover border-2 hover:scale-110 transition-all ease-in-out border-red-800 rounded-full cursor-pointer"
          ></div>
          <h3 className="text-lg">Ekta Tulsani</h3>
          <p>Director (Admin, CSR)</p>
        </div>
        <div className="text-center">
          <div
            style={snehabg}
            className="w-[150px] h-[150px] bg-cover border-2 hover:scale-110 transition-all ease-in-out border-red-800 rounded-full cursor-pointer"
          ></div>
          <h3 className="text-lg">Dr. Sneha Tulsani</h3>
          <p>Chief Executive Officer</p>
        </div>
        <div className="text-center">
          <div
            style={narendrabg}
            className="w-[150px] h-[150px] bg-cover border-2 hover:scale-110 transition-all ease-in-out border-red-800 rounded-full cursor-pointer"
          ></div>
          <h3 className="text-lg">Narendra Khemani</h3>
          <p>
            (General Manager – <br />
            Warehouse Operations)
          </p>
        </div>
        <div className="text-center">
          <div
            style={priyabg}
            className="w-[150px] h-[150px] bg-cover border-2 hover:scale-110 transition-all ease-in-out border-red-800 rounded-full cursor-pointer"
          ></div>
          <h3 className="text-lg">Priya Sengunthar </h3>
          <p>
            ( General Manager - <br /> Client Operations)
          </p>
        </div>
      </div>
    </div>
  );
};

export default Leadership;
