import LightGallery from "lightgallery/react";
import "./AwardsAccolades.css";

// import styles
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import "lightgallery/css/lg-autoplay.css";
import "lightgallery/css/lg-fullscreen.css";
import "lightgallery/css/lg-share.css";
import "lightgallery/css/lg-rotate.css";

// import plugins if you need
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import lgAutoplay from "lightgallery/plugins/autoplay";
import lgFullscreen from "lightgallery/plugins/fullscreen";
import lgShare from "lightgallery/plugins/share";
import lgRotate from "lightgallery/plugins/rotate";

const images = [
  {
    src: require("../asserts/awards/rsc awards (1).jpeg"),
    alt: "Corporate Office",
  },
  {
    src: require("../asserts/awards/rsc awards (2).jpeg"),
    alt: "Corporate Office",
  },
  {
    src: require("../asserts/awards/rsc awards (3).jpeg"),
    alt: "Corporate Office",
  },
  {
    src: require("../asserts/awards/rsc awards (4).jpeg"),
    alt: "Corporate Office",
  },
  {
    src: require("../asserts/awards/rsc awards (5).jpeg"),
    alt: "Corporate Office",
  },
  {
    src: require("../asserts/awards/rsc awards (6).jpeg"),
    alt: "Corporate Office",
  },
  {
    src: require("../asserts/awards/rsc awards (7).jpeg"),
    alt: "Corporate Office",
  },
  {
    src: require("../asserts/awards/rsc awards (8).jpeg"),
    alt: "Corporate Office",
  },
  {
    src: require("../asserts/awards/rsc awards (9).jpeg"),
    alt: "Corporate Office",
  },
  {
    src: require("../asserts/awards/rsc awards (10).jpeg"),
    alt: "Corporate Office",
  },
  {
    src: require("../asserts/awards/rsc awards (11).jpeg"),
    alt: "Corporate Office",
  },
];

export function AwardsAccolades() {
  const onInit = () => {
    console.log("lightGallery has been initialized");
  };
  // ...
  return (
    <div className="App">
      {/* Breadcumb */}
      <div className="h-32 bg-gray-800 flex flex-col items-center justify-center text-lg font-semibold text-white">
        <p>
          <a className="hover:underline" href="/">
            {" "}
            Home
          </a>{" "}
          /{" "}
          <a className="hover:underline" href="/about-us/awards-accolades">
            Awards & Accolades
          </a>
        </p>
      </div>
      <div className="flex flex-wrap justify-center gap-3 py-10">
        {images.map((image, index) => {
          return (
            <div key={index} className="flex  lg:px-6">
              <img
                alt={image.alt}
                src={image.src}
                className="bg-red-800 awards w-[300px] h-[300px]"
              />
              <p className="text-2xl font-medium lg:pb-20 pt-4">{image.para}</p>{" "}
            </div>
          );
        })}
      </div>
    </div>
  );
}
