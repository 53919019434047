import React from "react";
import { FooterRoutes, FooterTopRoutes } from "./FooterTopRoutes";
import { Link } from "react-router-dom";
import { FooterSecondRoutes } from "./FooterSecondRoues";
import { FooterThirdRoutes } from "./FooterThirdRoues";
import { FooterForthRoutes } from "./FooterForthRoutes";

const Footer = () => {
  return (
    <div className=" bg-gray-200  text-black  font-semibold -bottom-10">
      {/* <div className="border-b-2 border-white py-6 ">
        <ul className="  flex items-center flex-wrap md:justify-center gap-x-56 md:gap-20 px-4  ">
          {FooterTopRoutes.map((route) => {
            const { href, title } = route;
            return (
              <li className="group relative">
                <a href={href} className="flex items-center">
                  {title}
                </a>
                <span className="absolute bottom-0 left-0 w-0 bg-[#A2272E] h-0.5 group-hover:w-full transition-all duration-300"></span>
              </li>
            );
          })}
        </ul>
      </div> */}
      <div className="flex flex-wrap justify-between items-start md:px-10 px-5 py-6">
        <div className="md:w-1/4 w-full">
          <img
            className="w-16"
            src={require("../../Icons/logo_transparent.png")}
          />
          <p className="py-6 text-justify">
            Ramdas Sales India Pvt.Ltd is a leading pharmaceutical supply chain
            solution provider engaged in C&F, Super Stockist Operations with
            specialization in the Distribution, Logistics, Warehousing, and
            Licensing facilities in Western India.
          </p>
        </div>
        <div className="md:w-1/4 w-full md:px-12">
          <h4 className="text-lg uppercase border-b-2 border-[#A2272E] w-24 ">
            About Us
          </h4>
          <ul className="pt-6">
            {FooterSecondRoutes.map((route) => {
              const { href, title } = route;
              return (
                <li className="py-1 group relative">
                  <a href={href} className="flex items-center">
                    {title}
                  </a>
                  <span className="absolute bottom-0 left-0 w-0 bg-[#A2272E] h-0.5 group-hover:w-1/2 transition-all duration-300"></span>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="md:w-1/4 w-full">
          <h4 className="text-lg uppercase border-b-2  border-[#A2272E] w-32">
            Other Links
          </h4>
          <ul className="pt-6">
            {FooterThirdRoutes.map((route) => {
              const { href, title } = route;
              return (
                <li className="py-1 group relative">
                  <a href={href} className="flex items-center">
                    {title}
                  </a>
                  <span className="absolute bottom-0 left-0 w-0 bg-[#A2272E] h-0.5 group-hover:w-1/4 transition-all duration-300"></span>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="md:w-1/4 w-full">
          <h4 className="text-lg uppercase border-b-2 border-[#A2272E] w-32">
            Get In Touch
          </h4>
          <ul className="pt-6">
            {FooterForthRoutes.map((route) => {
              const { href, title, icon, subtitle, image } = route;
              return (
                <li className=" flex gap-2 group relative">
                  <a href={href} className="pt-2 ">
                    {icon} {title} <br />
                    {subtitle} {image}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className="border-t-2 py-4 border-white md:px-10 px-5 text-sm">
        <p>
          Copyright © 2024.{" "}
          <span className="hover:text-red-800 cursor-pointer">
            Ramdas Sales India Pvt.Ltd
          </span>{" "}
          All Rights Reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;
