import LightGallery from "lightgallery/react";
import "./Gallery.css";

// import styles
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import "lightgallery/css/lg-autoplay.css";
import "lightgallery/css/lg-fullscreen.css";
import "lightgallery/css/lg-share.css";
import "lightgallery/css/lg-rotate.css";

// import plugins if you need
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import lgAutoplay from "lightgallery/plugins/autoplay";
import lgFullscreen from "lightgallery/plugins/fullscreen";
import lgShare from "lightgallery/plugins/share";
import lgRotate from "lightgallery/plugins/rotate";

const images = [
  {
    src: require("../asserts/aboutus1.jpg"),
    alt: "Corporate Office",
    para: "Corporate Office",
  },
  {
    src: require("../asserts/aboutus1.jpg"),
    alt: "Corporate Office",
    para: "Corporate Office",
  },
  {
    src: require("../asserts/aboutus1.jpg"),
    alt: "Corporate Office",
    para: "Corporate Office",
  },
  {
    src: require("../asserts/aboutus1.jpg"),
    alt: "Corporate Office",
    para: "Corporate Office",
  },
  {
    src: require("../asserts/aboutus1.jpg"),
    alt: "Corporate Office",
    para: "Corporate Office",
  },
  {
    src: require("../asserts/aboutus1.jpg"),
    alt: "Corporate Office",
    para: "Corporate Office",
  },
];

export function Gallery() {
  const onInit = () => {
    console.log("lightGallery has been initialized");
  };
  return (
    <div className="App">
      {/* Breadcumb */}
      <div className="h-32 bg-gray-800 flex flex-col items-center justify-center text-lg font-semibold text-white">
        <p>
          <a className="hover:underline" href="/">
            {" "}
            Home
          </a>{" "}
          /{" "}
          <a className="hover:underline" href="/infrastructures/images">
            Images
          </a>
        </p>
      </div>
      <LightGallery
        onInit={onInit}
        speed={500}
        plugins={[
          lgThumbnail,
          lgZoom,
          lgAutoplay,
          lgFullscreen,
          lgRotate,
          lgShare,
        ]}
      >
        {images.map((image, index) => {
          return (
            <a href={image.src} key={index}>
              <img
                alt={image.alt}
                src={image.src}
                className="bg-red-800 gallery"
              />
              <p className="text-2xl font-medium pb-20 pt-4">{image.para}</p>{" "}
            </a>
          );
        })}
      </LightGallery>
    </div>
  );
}
