export const FooterSecondRoutes = [
  {
    title: "Company Overview",
    href: "/about-us/company-overview",
  },
  {
    title: "Leadership",
    href: "/about-us/leadership",
  },
  {
    title: "Vision  Mission",
    href: "/about-us/vision-mission",
  },
  {
    title: "Awards & Accolades",
    href: "/about-us/awards-accolades",
  },
  {
    title: "Our Journey",
    href: "/about-us/our-journey",
  },
  // Add more subpages as needed
];
