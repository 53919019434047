import React, { Component } from "react";
import "./style.css";

class SupplyCard extends Component {
  constructor() {
    super();
    this.state = {
      count1: 10000,
      count2: 0,
      count3: 0,
      count4: 30,
    };
  }

  counter = (minimum, maximum, countKey, speed) => {
    let currentCount = minimum;
    // The speed parameter controls how often (in milliseconds) the setInterval callback is invoked
    const intervalId = setInterval(() => {
      this.setState({ [countKey]: currentCount });
      currentCount++;
      if (currentCount > maximum) {
        clearInterval(intervalId);
      }
    }, speed); // Speed controls how fast the counter updates
  };

  componentDidMount() {
    // Adjust the speed by changing the last parameter. Smaller numbers mean faster counting.
    this.counter(99900, 100000, "count1", 200); // Fast
    this.counter(6900, 7000, "count2", 200); // Moderately fast
    this.counter(3900, 4000, "count3", 200); // Slower
    this.counter(0, 30, "count4", 200); // Very slow
  }

  render() {
    return (
      <div className="bg-gray-200">
        <div className="flex flex-col justify-between items-center  font-semibold lg:px-32 space-y-4 py-8 ">
          <h2 className="lg:text-4xl text-2xl text-red-800 font-bold  pb-3 border-b-4 border-red-800 mx-2 lg:mx-0">
            Key Metrics
          </h2>
          <p className="lg:w-1/2 text-xl px-2 lg:px-0 text-center">
            We remain fully committed to our customers and go an extra mile to
            serve them. The numbers show the believe and trust our clients have
            on us.
          </p>
        </div>
        <div className="flex flex-wrap justify-center items-center mt-10">
          <div className="bg-red-800 text-center lg:py-14 py-16 lg:px-10 px-4 space-y-4 text-white lg:w-[25%] lg:h-[350px] w-[100%] button-pop-in">
            <img
              className="m-auto section4 lg:w-[100px] w-[80px]"
              src={require("../Pages/asserts/graph_10838801.png")}
            />
            {/* <span className="text-4xl font-semibold">1 ,00,000+</span> */}
            <span id="count1" className="text-4xl font-semibold">
              {this.state.count1}+
            </span>
            <h4 className="text-lg font-semibold">
              Gross Volume Shippers Delivered per month
            </h4>
          </div>
          <div className="bg-gray-200 text-center lg:py-14 py-16 lg:px-10 px-4 space-y-4 lg:w-[25%] lg:h-[350px] w-[100%] button-pop-in">
            <img
              className="m-auto lg:w-[100px] w-[80px]"
              src={require("../Pages/asserts/invoice_2474482.png")}
            />
            {/* <p className="text-4xl font-semibold text-red-800">7,000+</p> */}
            <span id="count2" className="text-4xl font-semibold text-red-800">
              {this.state.count2}+
            </span>

            <h4 className="text-lg font-semibold">Invoices per Month</h4>
          </div>
          <div className="bg-red-800 text-center lg:py-14 py-16 lg:px-10 px-4 space-y-4 text-white lg:w-[25%] lg:h-[350px] w-[100%] button-pop-in">
            <img
              className="m-auto section4 lg:w-[100px] w-[80px]"
              src={require("../Pages/asserts/skus.png")}
            />
            {/* <p className="text-4xl font-semibold">4,000+</p> */}
            <span id="count3" className="text-4xl font-semibold">
              {this.state.count3}+
            </span>

            <h4 className="text-lg font-semibold">SKUs handled</h4>
          </div>
          <div className="bg-gray-200 text-center lg:py-14 py-16 lg:px-10 px-4 space-y-4 lg:w-[25%] lg:h-[350px] w-[100%] button-pop-in">
            <img
              className="m-auto lg:w-[100px] w-[80px]"
              src={require("../Pages/asserts/delivery.png")}
            />
            {/* <p className="text-4xl font-semibold text-red-800">7,000+</p> */}
            <span id="count4" className="text-4xl font-semibold text-red-800">
              {this.state.count4}+
            </span>

            <h4 className="text-lg font-semibold">Delivery points</h4>
          </div>
        </div>
      </div>
    );
  }
}

export default SupplyCard;
