import React, { useEffect, useState } from "react";

const ReturnPolicy = () => {
  return (
    <div className="h-full">
      <div className="text-white bg-gray-800 text-center py-10">
        <h2 className="text-2xl md:text-6xl font-semibold">Return Policy</h2>
      </div>

      <div className="text-center md:text-right flex justify-center flex-wrap items-center mb-[10px] ">
        <h2 className="mt-[70px] inline-block right-10 font-bold text-[17px] md:text-xl text-[#003152] uppercase rounded-b-lg pl-2 pr-2 border-b-2 border-[#003152]">
          Return Policy
        </h2>
      </div>

      <div className="md:m-[40px] pl-4 pr-4 md:pl-[100px] md:pr-[100px] pb-[40px] pt-[40px] text text-[#003152]">
        <div className="space-y-5">
          <div className="space-y-5">
            <h3 className="text-[15px] md:text-lg font-semibold">
              1. YOUR AGREEMENT
            </h3>

            <p className="text-[13px] md:text-sm">
              By using this Site, you agree to be bound by, and to comply with,
              these Terms and Conditions. If you do not agree to these Terms and
              Conditions, please do not use this site. PLEASE NOTE: <br />
              <br />
              We reserve the right, at our sole discretion, to change, modify or
              otherwise alter these Terms and Conditions at any time. Unless
              otherwise indicated, amendments will become effective immediately.
              Please review these Terms and Conditions periodically. Your
              continued use of the Site following the posting of changes and/or
              modifications will constitute your acceptance of the revised Terms
              and Conditions and the reasonableness of these standards for
              notice of changes. For your information, this page was last
              updated as of the date at the top of these terms and conditions.
            </p>
          </div>

          <div className="space-y-5 ">
            <h4 className="text-[15px] md:text-lg font-semibold ">
              2. PRIVACY
            </h4>
            <p className="text-[13px] md:text-sm">
              Please review our Privacy Policy, which also governs your visit to
              this Site, to understand our practices.
            </p>
          </div>

          <div className="space-y-5">
            <h4 className="text-[15px] md:text-lg font-semibold">
              3. LINKED SITES
            </h4>
            <p className="text-[13px] md:text-sm">
              This Site may contain links to other independent third-party Web
              sites ("Linked Sites”). These Linked Sites are provided solely as
              a convenience to our visitors. Such Linked Sites are not under our
              control, and we are not responsible for and does not endorse the
              content of such Linked Sites, including any information or
              materials contained on such Linked Sites. You will need to make
              your own independent judgment regarding your interaction with
              these Linked Sites.
            </p>
          </div>

          <div className="space-y-5">
            <h4 className=" text-[15px] md:text-lg font-semibold">
              4. FORWARD LOOKING STATEMENTS
            </h4>

            <p className="text-[13px] md:text-sm">
              All materials reproduced on this site speak as of the original
              date of publication or filing. The fact that a document is
              available on this site does not mean that the information
              contained in such document has not been modified or superseded by
              events or by a subsequent document or filing. We have no duty or
              policy to update any information or statements contained on this
              site and, therefore, such information or statements should not be
              relied upon as being current as of the date you access this site.
            </p>
          </div>

          <div className="space-y-5">
            <h4 className=" text-[15px] md:text-lg font-semibold">
              5. DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY
            </h4>

            <p className="text-[13px] md:text-sm">
              A. THIS SITE MAY CONTAIN INACCURACIES AND TYPOGRAPHICAL ERRORS. WE
              DOES NOT WARRANT THE ACCURACY OR COMPLETENESS OF THE MATERIALS OR
              THE RELIABILITY OF ANY ADVICE, OPINION, STATEMENT OR OTHER
              INFORMATION DISPLAYED OR DISTRIBUTED THROUGH THE SITE. YOU
              EXPRESSLY UNDERSTAND AND AGREE THAT: (i) YOUR USE OF THE SITE,
              INCLUDING ANY RELIANCE ON ANY SUCH OPINION, ADVICE, STATEMENT,
              MEMORANDUM, OR INFORMATION CONTAINED HEREIN, SHALL BE AT YOUR SOLE
              RISK; (ii) THE SITE IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE"
              BASIS; (iii) EXCEPT AS EXPRESSLY PROVIDED HEREIN WE DISCLAIM ALL
              WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT
              NOT LIMITED TO IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR
              A PARTICULAR PURPOSE, WORKMANLIKE EFFORT, TITLE AND
              NON-INFRINGEMENT; (iv) WE MAKE NO WARRANTY WITH RESPECT TO THE
              RESULTS THAT MAY BE OBTAINED FROM THIS SITE, THE PRODUCTS OR
              SERVICES ADVERTISED OR OFFERED OR MERCHANTS INVOLVED; (v) ANY
              MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE
              SITE IS DONE AT YOUR OWN DISCRETION AND RISK; and (vi) YOU WILL BE
              SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR FOR
              ANY LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH
              MATERIAL.
            </p>
            <p className="text-[13px] md:text-sm">
              B. YOU UNDERSTAND AND AGREE THAT UNDER NO CIRCUMSTANCES,
              INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE, SHALL WE BE LIABLE FOR
              ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE OR
              CONSEQUENTIAL DAMAGES THAT RESULT FROM THE USE OF, OR THE
              INABILITY TO USE, ANY OF OUR SITES OR MATERIALS OR FUNCTIONS ON
              ANY SUCH SITE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF
              SUCH DAMAGES. THE FOREGOING LIMITATIONS SHALL APPLY
              NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED
              REMEDY.
            </p>
          </div>

          <div className="space-y-5">
            <h4 className=" text-[15px] md:text-lg font-semibold">
              6. EXCLUSIONS AND LIMITATIONS
            </h4>

            <p className="text-[13px] md:text-sm">
              SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN
              WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR
              INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, OUR LIABILITY IN
              SUCH JURISDICTION SHALL BE LIMITED TO THE MAXIMUM EXTENT PERMITTED
              BY LAW.
            </p>
          </div>
          <div className="space-y-5">
            <h4 className=" text-[15px] md:text-lg font-semibold">
              7. OUR PROPRIETARY RIGHTS
            </h4>

            <p className="text-[13px] md:text-sm">
              This Site and all its Contents are intended solely for personal,
              non-commercial use. Except as expressly provided, nothing within
              the Site shall be construed as conferring any license under our or
              any third party's intellectual property rights, whether by
              estoppel, implication, waiver, or otherwise. Without limiting the
              generality of the foregoing, you acknowledge and agree that all
              content available through and used to operate the Site and its
              services is protected by copyright, trademark, patent, or other
              proprietary rights. You agree not to: (a) modify, alter, or deface
              any of the trademarks, service marks, trade dress (collectively
              "Trademarks") or other intellectual property made available by us
              in connection with the Site; (b) hold yourself out as in any way
              sponsored by, affiliated with, or endorsed by us, or any of our
              affiliates or service providers; (c) use any of the Trademarks or
              other content accessible through the Site for any purpose other
              than the purpose for which we have made it available to you; (d)
              defame or disparage us, our Trademarks, or any aspect of the Site;
              and (e) adapt, translate, modify, decompile, disassemble, or
              reverse engineer the Site or any software or programs used in
              connection with it or its products and services.
            </p>
            <p className="text-[13px] md:text-sm">
              The framing, mirroring, scraping or data mining of the Site or any
              of its content in any form and by any method is expressly
              prohibited.
            </p>
          </div>
          <div className="space-y-5">
            <h4 className=" text-[15px] md:text-lg font-semibold">
              8. INDEMNITY
            </h4>

            <p className="text-[13px] md:text-sm">
              By using the Site web sites you agree to indemnify us and
              affiliated entities (collectively "Indemnities") and hold them
              harmless from any and all claims and expenses, including (without
              limitation) attorney's fees, arising from your use of the Site web
              sites, your use of the Products and Services, or your submission
              of ideas and/or related materials to us or from any person's use
              of any ID, membership or password you maintain with any portion of
              the Site, regardless of whether such use is authorized by you.
            </p>
          </div>
          <div className="space-y-5">
            <h4 className=" text-[15px] md:text-lg font-semibold">
              9. COPYRIGHT AND TRADEMARK NOTICE
            </h4>

            <p className="text-[13px] md:text-sm">
              Except our generated dummy copy, which is free to use for private
              and commercial use, all other text is copyrighted.
              generator.lorem-ipsum.info © 2013, all rights reserved
            </p>
          </div>
          <div className="space-y-5">
            <h4 className=" text-[15px] md:text-lg font-semibold">
              10. INTELLECTUAL PROPERTY INFRINGEMENT CLAIMS
            </h4>

            <p className="text-[13px] md:text-sm">
              It is our policy to respond expeditiously to claims of
              intellectual property infringement. We will promptly process and
              investigate notices of alleged infringement and will take
              appropriate actions under the Digital Millennium Copyright Act
              ("DMCA") and other applicable intellectual property laws. Notices
              of claimed infringement should be directed to:
              generator.lorem-ipsum.info 126 Electricov St. Kiev, Kiev 04176
              Ukraine contact@lorem-ipsum.info.
            </p>
          </div>
          <div className="space-y-5">
            <h4 className=" text-[15px] md:text-lg font-semibold">
              11. PLACE OF PERFORMANCE
            </h4>

            <p className="text-[13px] md:text-sm">
              This Site is controlled, operated and administered by us from our
              office in Kiev, Ukraine. We make no representation that materials
              at this site are appropriate or available for use at other
              locations outside of the Ukraine and access to them from
              territories where their contents are illegal is prohibited. If you
              access this Site from a location outside of the Ukraine, you are
              responsible for compliance with all local laws.
            </p>
          </div>
          <div className="space-y-5">
            <h4 className=" text-[15px] md:text-lg font-semibold">
              12. INTELLECTUAL PROPERTY INFRINGEMENT CLAIMS
            </h4>

            <p className="text-[13px] md:text-sm">
              It is our policy to respond expeditiously to claims of
              intellectual property infringement. We will promptly process and
              investigate notices of alleged infringement and will take
              appropriate actions under the Digital Millennium Copyright Act
              ("DMCA") and other applicable intellectual property laws. Notices
              of claimed infringement should be directed to:
              generator.lorem-ipsum.info 126 Electricov St. Kiev, Kiev 04176
              Ukraine contact@lorem-ipsum.info.
            </p>
          </div>
          <div className="space-y-5">
            <h4 className=" text-[15px] md:text-lg font-semibold">
              13. INTELLECTUAL PROPERTY INFRINGEMENT CLAIMS
            </h4>

            <p className="text-[13px] md:text-sm">
              It is our policy to respond expeditiously to claims of
              intellectual property infringement. We will promptly process and
              investigate notices of alleged infringement and will take
              appropriate actions under the Digital Millennium Copyright Act
              ("DMCA") and other applicable intellectual property laws. Notices
              of claimed infringement should be directed to:
              generator.lorem-ipsum.info 126 Electricov St. Kiev, Kiev 04176
              Ukraine contact@lorem-ipsum.info.
            </p>
          </div>
          <div className="space-y-5">
            <h4 className=" text-[15px] md:text-lg font-semibold">
              14. INTELLECTUAL PROPERTY INFRINGEMENT CLAIMS
            </h4>

            <p className="text-[13px] md:text-sm">
              It is our policy to respond expeditiously to claims of
              intellectual property infringement. We will promptly process and
              investigate notices of alleged infringement and will take
              appropriate actions under the Digital Millennium Copyright Act
              ("DMCA") and other applicable intellectual property laws. Notices
              of claimed infringement should be directed to:
              generator.lorem-ipsum.info 126 Electricov St. Kiev, Kiev 04176
              Ukraine contact@lorem-ipsum.info.
            </p>
          </div>
          <div className="space-y-5">
            <h4 className=" text-[15px] md:text-lg font-semibold">
              15. INTELLECTUAL PROPERTY INFRINGEMENT CLAIMS
            </h4>

            <p className="text-[13px] md:text-sm">
              It is our policy to respond expeditiously to claims of
              intellectual property infringement. We will promptly process and
              investigate notices of alleged infringement and will take
              appropriate actions under the Digital Millennium Copyright Act
              ("DMCA") and other applicable intellectual property laws. Notices
              of claimed infringement should be directed to:
              generator.lorem-ipsum.info 126 Electricov St. Kiev, Kiev 04176
              Ukraine contact@lorem-ipsum.info.
            </p>
          </div>
          <div className="space-y-5">
            <h4 className=" text-[15px] md:text-lg font-semibold">
              16. INTELLECTUAL PROPERTY INFRINGEMENT CLAIMS
            </h4>

            <p className="text-[13px] md:text-sm">
              It is our policy to respond expeditiously to claims of
              intellectual property infringement. We will promptly process and
              investigate notices of alleged infringement and will take
              appropriate actions under the Digital Millennium Copyright Act
              ("DMCA") and other applicable intellectual property laws. Notices
              of claimed infringement should be directed to:
              generator.lorem-ipsum.info 126 Electricov St. Kiev, Kiev 04176
              Ukraine contact@lorem-ipsum.info.
            </p>
          </div>
          <div className="space-y-5">
            <h4 className=" text-[15px] md:text-lg font-semibold">
              17. INTELLECTUAL PROPERTY INFRINGEMENT CLAIMS
            </h4>

            <p className="text-[13px] md:text-sm">
              It is our policy to respond expeditiously to claims of
              intellectual property infringement. We will promptly process and
              investigate notices of alleged infringement and will take
              appropriate actions under the Digital Millennium Copyright Act
              ("DMCA") and other applicable intellectual property laws. Notices
              of claimed infringement should be directed to:
              generator.lorem-ipsum.info 126 Electricov St. Kiev, Kiev 04176
              Ukraine contact@lorem-ipsum.info.
            </p>
          </div>
          <div className="space-y-5">
            <h4 className=" text-[15px] md:text-lg font-semibold">
              18. INTELLECTUAL PROPERTY INFRINGEMENT CLAIMS
            </h4>

            <p className="text-[13px] md:text-sm">
              It is our policy to respond expeditiously to claims of
              intellectual property infringement. We will promptly process and
              investigate notices of alleged infringement and will take
              appropriate actions under the Digital Millennium Copyright Act
              ("DMCA") and other applicable intellectual property laws. Notices
              of claimed infringement should be directed to:
              generator.lorem-ipsum.info 126 Electricov St. Kiev, Kiev 04176
              Ukraine contact@lorem-ipsum.info.
            </p>
          </div>
          <div className="space-y-5">
            <h4 className=" text-[15px] md:text-lg font-semibold">
              19. INTELLECTUAL PROPERTY INFRINGEMENT CLAIMS
            </h4>

            <p className="text-[13px] md:text-sm">
              It is our policy to respond expeditiously to claims of
              intellectual property infringement. We will promptly process and
              investigate notices of alleged infringement and will take
              appropriate actions under the Digital Millennium Copyright Act
              ("DMCA") and other applicable intellectual property laws. Notices
              of claimed infringement should be directed to:
              generator.lorem-ipsum.info 126 Electricov St. Kiev, Kiev 04176
              Ukraine contact@lorem-ipsum.info.
            </p>
          </div>
          <div className="space-y-5">
            <h4 className=" text-[15px] md:text-lg font-semibold">
              20. INTELLECTUAL PROPERTY INFRINGEMENT CLAIMS
            </h4>

            <p className="text-[13px] md:text-sm">
              It is our policy to respond expeditiously to claims of
              intellectual property infringement. We will promptly process and
              investigate notices of alleged infringement and will take
              appropriate actions under the Digital Millennium Copyright Act
              ("DMCA") and other applicable intellectual property laws. Notices
              of claimed infringement should be directed to:
              generator.lorem-ipsum.info 126 Electricov St. Kiev, Kiev 04176
              Ukraine contact@lorem-ipsum.info.
            </p>
          </div>
          <div className="space-y-5">
            <h4 className=" text-[15px] md:text-lg font-semibold">
              21. INTELLECTUAL PROPERTY INFRINGEMENT CLAIMS
            </h4>

            <p className="text-[13px] md:text-sm">
              It is our policy to respond expeditiously to claims of
              intellectual property infringement. We will promptly process and
              investigate notices of alleged infringement and will take
              appropriate actions under the Digital Millennium Copyright Act
              ("DMCA") and other applicable intellectual property laws. Notices
              of claimed infringement should be directed to:
              generator.lorem-ipsum.info 126 Electricov St. Kiev, Kiev 04176
              Ukraine contact@lorem-ipsum.info.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReturnPolicy;
