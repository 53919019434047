import React, { useRef, useState } from "react";
import GoogleMapComponent from "../../Components/GoogleMapComponent/GoogleMapComponent";
import emailjs from "emailjs-com";
import "./style.css";

const ContactUs = () => {
  const imageRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();

    if (
      !formData.name ||
      !formData.email ||
      !formData.phone ||
      !formData.message ||
      !isChecked
    ) {
      setErrorMessage("Please fill in all required fields.");
      return;
    }

    setIsLoading(true);
    setErrorMessage(""); // Clear any existing error messages.

    try {
      // Assuming you have formData set up to capture your form data correctly
      const templateParams = {
        from_name: formData.name,
        from_email: formData.email,
        message: formData.message,
        from_phone: formData.phone,
      };

      await emailjs.send(
        "service_09rggcm",
        "template_qgwuifb",
        templateParams,
        "kcZE7X1RdgXi5EwDW"
      );

      setSuccessMessage("Your message has been sent successfully!");
      // Reset the form if needed
      setFormData({ name: "", email: "", phone: "", message: "" });
      setIsChecked(false); // Reset the checkbox if needed
    } catch (error) {
      console.error("Failed to send message:", error);
      setErrorMessage("Failed to send message. Please try again.");
    }

    setIsLoading(false);
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.clientX);
    setScrollLeft(imageRef.current.scrollLeft);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const x = e.clientX;
    const walk = (x - startX) * 3;
    imageRef.current.scrollLeft = scrollLeft - walk;
  };

  return (
    <div className="  pb-6 bg-gray-200">
      {/* Breadcumb */}
      <div className="h-32 bg-gray-700 flex flex-col items-center justify-center text-lg font-semibold text-white">
        <p>
          <a className="hover:underline" href="/">
            {" "}
            Home
          </a>{" "}
          /{" "}
          <a className="hover:underline" href="/contact-us">
            Contact Us
          </a>
        </p>
      </div>

      <div className="flex flex-wrap gap-32 justify-center items-center  p-5 my-10 ">
        <div className="w-[350px] lg:w-[450px] px-10 bg-white py-20 border-t-2 border-red-800 lg:px-8 lg:gap-3 rounded-xl mt-10 space-y-4">
          <h4 className="text-2xl font-bold text-red-800 pb-4">Head Office</h4>
          <div className="flex items-start gap-x-4 z-10 ">
            <div className="lg:text-2xl">
              <i class="fa-solid fa-location-dot text-red-800"></i>
            </div>
            <div className="">
              <h5 className="text-xl font-bold text-red-800 ">Address</h5>
              <p className="lg:w-[350px] text-lg">
                Ramdas Sales India Pvt.Ltd, G-10, Mewad, E S Patanwala Complex,
                LBSMarg, Ghatkopar West, Mumbai - 400086
              </p>
            </div>
          </div>
          <div className="flex items-start gap-x-4 z-10  ">
            <div className="lg:text-lg">
              <i class="fa-solid fa-phone text-red-800"></i>
            </div>
            <div>
              <h5 className="text-xl font-bold text-red-800 ">Phone</h5>

              <a href="tel:919821307100">
                <p className="text-lg">
                  +919821307100, +91 - 22 - 25002846 / 25000313
                </p>
              </a>
            </div>
          </div>
          <div className="flex items-start gap-x-4 z-10 ">
            <div className="lg:text-lg">
              <i class="fa-solid fa-envelope text-red-800"></i>
            </div>
            <div>
              <h5 className="text-xl font-bold text-red-800 ">Email</h5>
              <a href="mailto:info@ramdas.co.in">
                <p className="lg:text-lg">info@ramdas.co.in</p>
              </a>
            </div>
          </div>
          <div className="flex items-start gap-x-4 z-10 ">
            <div className="lg:text-lg">
              <i class="fa-solid fa-warehouse  text-red-800"></i>
            </div>
            <div>
              <h5 className="text-xl font-bold text-red-800 ">Warehouse</h5>
              <p className="text-lg">
                Bldg No. 18 Arihant Commercial Complex, Near Kopar Bus Stop,
                Purna Village Bhiwandi, 421302
              </p>
            </div>
          </div>
        </div>

        <iframe
          src="https://www.google.com/maps/embed?pb=!1m26!1m12!1m3!1d966392.9744563131!2d72.76542798447048!3d18.888298621680857!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m11!3e6!4m3!3m2!1d18.5246164!2d73.8629674!4m5!1s0x3be7bd001ef6fd4f%3A0x14b183df391bc427!2sramdas%20sales%20corporation!3m2!1d19.252031199999998!2d73.0281881!5e0!3m2!1sen!2sin!4v1710869260269!5m2!1sen!2sin"
          width="650"
          className="relative lg:right-20 lg:pr-4 lg:py-4 rounded-lg bg-gray-700 w-[550px] lg:w-[600px] mt-4"
          height="540"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>

      <div className="bg-[url('/public/assets/Picture1.png')] bg-red-800  py-32 w-full -mb-32 mt-20 bg-no-repeat bg-cover bg-center  "></div>

      <div className=" flex flex-wrap flex-col lg:px-44 lg:py-20 lg:mx-52 bg-white shadow-lg rounded-lg">
        <div>
          <h2 className="lg:text-5xl text-2xl font-semibold py-3 text-center text-gray-800">
            Get in{" "}
            <span className="lg:text-6xl text-3xl font-bold text-red-800">
              Touch
            </span>
          </h2>
        </div>

        {/* Form */}
        <div className="py-10">
          <form className="form lg:px-0 px-10" onSubmit={handleSendMessage}>
            <div className="mb-4 flex flex-wrap">
              <div className="w-1/2 pr-2 relative ">
                <input
                  required
                  placeholder="Full name "
                  type="text"
                  id="name"
                  name="name"
                  onChange={handleInputChange}
                  className=" border-gray-300 w-full border  px-6 py-2"
                />
                <i className="fa-solid fa-user absolute right-4 top-3 text-gray-400"></i>
              </div>

              <div className="w-1/2 pl-2 relative">
                <input
                  required
                  placeholder="Email"
                  type="email"
                  id="email"
                  name="email"
                  onChange={handleInputChange}
                  className="border-gray-300 w-full border  px-6 py-2 "
                />
                <i className="fa-regular fa-envelope absolute right-3 top-3 text-gray-400"></i>
              </div>
            </div>

            <div className="mb-4 ">
              <div className="relative">
                <input
                  required
                  placeholder="Phone number"
                  type="number"
                  id="number"
                  name="phone"
                  onChange={handleInputChange}
                  className="border-gray-300 w-full border px-6 py-2   "
                />
                <i className="fa-solid fa-square-arrow-up-right absolute right-3 top-3 text-gray-400"></i>
              </div>
            </div>

            <div className="mb-4">
              <textarea
                required
                placeholder=" Message"
                id="message"
                name="message"
                onChange={handleInputChange}
                className="border-gray-300 w-full border  px-6 py-2 "
              ></textarea>
            </div>

            <div className="flex flex-wrap lg:justify-between justify-center items-center ">
              {/* Checkbox */}
              <label className="flex items-center ">
                <input
                  required
                  type="checkbox"
                  id="agreeCheckbox"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  className="mr-2 "
                />
                <span className="text-gray-800">
                  I agree to the terms & conditions.
                </span>
              </label>
              <button
                type="submit"
                className={`bg-red-800 text-white px-5 py-3 mt-5 rounded-lg flex justify-center items-center ${
                  isLoading ? "" : "hover:scale-105"
                }`}
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <div className="button-loader"></div>
                    {/* <span className="ml-2">Sending...</span> */}
                  </>
                ) : (
                  "Send Message"
                )}
              </button>

              {successMessage && (
                <div className="mt-3 text-green-500 text-center">
                  {successMessage}
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
