import React from "react";

const VissionMission = () => {
  return (
    <div className="pb-10">
      {" "}
      {/* Breadcumb */}
      <div className="h-32 bg-gray-800 flex flex-col items-center justify-center text-lg font-semibold text-white">
        <p>
          <a className="hover:underline" href="/">
            {" "}
            Home
          </a>{" "}
          /{" "}
          <a className="hover:underline" href="/about-us/vission-mission">
            Vision Mission
          </a>
        </p>
      </div>
      {/* Vission Mission */}
      <div className=" flex flex-wrap gap-10 justify-center items-center  p-5 my-10">
        <div className="  ">
          <p className="font-bold lg:text-4xl text-2xl pb-4  ">Vision</p>
          <p className="lg:text-justify lg:w-[500px] ">
            RSIPL aims to be a customer - oriented 3PL provider in the
            healthcare industry with dynamic supply chain management solutions,
            with a commitment to excellence and with a moral responsibility to
            all stakeholders.
          </p>
        </div>
        <div className="  ">
          <p className="font-bold lg:text-4xl text-2xl pb-4  ">Mission</p>
          <p className=" lg:text-justify lg:w-[400px]">
            To simplify and modify the distribution logistics across India by
            bringing the best in class supply chain services to Indian
            pharmaceutical market.
          </p>
        </div>
      </div>
      <div className="lg:w-[940px] rounded-[10px] m-auto bg-red-800 px-10 py-10 text-white  flex flex-wrap gap-10 justify-start items-left  p-5 my-10 ">
        <p className=" text-2xl font-bold ">
          Our Values are summarized with acronym for T.E.A.M
        </p>
        <ul className="text-xl flex flex-col gap-3">
          <li>
            <span className="font-bold text-3xl">T</span>ransparency: Building a
            transparent and trustworthy relationship with our clients.
          </li>
          <li>
            <span className="font-bold text-3xl">E</span>fficiency and Empathy:
            Efficient teams along with a culture of respect and empathy.
          </li>
          <li>
            <span className="font-bold text-3xl">A</span>ccountability &
            Agility: To be accountable and agile at every work we do.
          </li>
          <li>
            <span className="font-bold text-3xl">M</span>indfulness: Ensure that
            we are mindful fiscally and socially in our daily operations.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default VissionMission;
