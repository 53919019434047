import React from "react";

const ReverseLogistics = () => {
  return (
    <div>
      <div className="shadow-2xl lg:w-[800px] m-auto px-10 py-20 space-y-4 text-justify ">
        <h2 className="text-2xl font-semibold">Reverse logistics</h2>
        <p>
          Reverse logistics in pharmaceutical distribution involves the
          management of returned products, expired medications, and the overall
          optimization of the supply chain in the reverse direction. The
          pharmaceutical industry operates within strict regulatory frameworks.
          Proper handling of returned medications is not just good business
          practice but also a legal requirement.
        </p>
        <p>
          At Ramdas Sales, we ensure compliance with regulations governing the
          disposal, destruction, or potential resale of returned pharmaceutical
          products.
        </p>
        <p className="text-lg font-semibold">
          Key Features of Reverse Logistics Operations include:
        </p>

        <ul className="space-y-4">
          <li className="list-disc">
            <span className="">
              Assessing the condition of returned products, determining if they
              can be restocked, repurposed, or safely disposed of, thereby
              reducing financial losses for pharmaceutical companies and
              minimizing environmental impact.
            </span>
          </li>
          <li className="list-disc">
            <span className="">Quality Assurance</span>
          </li>
          <li className="list-disc">
            <span className="">
              Ensuring proper storage conditions are maintained throughout the
              return process.
            </span>
          </li>
          <li className="list-disc">
            <span className="">
              We continuously streamline the returns process, reducing waste,
              and maximizing the value of returned products, which can help
              companies, can improve their bottom line.
            </span>
          </li>
          <li className="list-disc">
            <span className="">
              Settling the Reverse Logistics accounting for stockists in a
              timely manner ensuring customer satisfaction.
            </span>
          </li>
          <li className="list-disc">
            <span className="">
              Working as per principal companies SOPs in handling return goods
              to ensure safety in disposal and enhance customer satisfaction.
            </span>
          </li>
          <li className="list-disc">
            <span className="">
              Data Analytics and Reporting provided by us enables our
              pharmaceutical clientele to identify the areas of improvement,
              product performance and return trends.
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ReverseLogistics;
