export const FooterForthRoutes = [
  {
    title:
      "G-10, Mewad, E S Patanwala Complex, LBSMarg, Ghatkopar West, Mumbai - 400086",
    icon: <i className="fa-solid fa-location-dot  text-red-800"></i>,
  },
  {
    title: <a href="mailto:info@ramdas.co.in">info@ramdas.co.in</a>,
    icon: <i class="fa-solid fa-envelope text-red-800"></i>,
  },
  {
    title: "+91 9821307100, ",
    subtitle: " +91 - 22 - 25002846/ 25000313",
    icon: <i class="fa-solid fa-phone text-red-800"></i>,
  },
];
