import React from "react";
import infra1 from "./asserts/infra1.jpg";
import infra2 from "./asserts/infra2.jpg";
import infra3 from "./asserts/infra3.jpg";
import infra4 from "./asserts/infra4.jpg";
import infra5 from "./asserts/infra5.jpg";
import infra6 from "./asserts/infra6.jpg";
import infra7 from "./asserts/infra7.jpg";
import infra8 from "./asserts/infra8.jpg";
import infra9 from "./asserts/infra9.jpg";

const InfraStructure = () => {
  const handleHover = (event) => {
    event.target.style.transform = "scale(1.05)";
  };

  const handleLeave = (event) => {
    event.target.style.transform = "scale(1)";
  };

  const bginfra1 = {
    backgroundImage: `url(${infra1})`,
  };
  const bginfra2 = {
    backgroundImage: `url(${infra2})`,
  };
  const bginfra3 = {
    backgroundImage: `url(${infra3})`,
  };
  const bginfra4 = {
    backgroundImage: `url(${infra4})`,
  };
  const bginfra5 = {
    backgroundImage: `url(${infra5})`,
  };
  const bginfra6 = {
    backgroundImage: `url(${infra6})`,
  };
  const bginfra7 = {
    backgroundImage: `url(${infra7})`,
  };
  const bginfra8 = {
    backgroundImage: `url(${infra8})`,
  };
  const bginfra9 = {
    backgroundImage: `url(${infra9})`,
  };
  return (
    <div>
      <div className="h-32 bg-gray-800 flex flex-col items-center justify-center text-lg font-semibold text-white">
        <p>
          <a className="hover:underline" href="/">
            {" "}
            Home
          </a>{" "}
          /{" "}
          <a className="hover:underline" href="/infraStructure">
            InfraStructure
          </a>
        </p>
      </div>

      <div className="flex flex-wrap justify-center items-center">
        <div className="lg:w-[600px] w-[400px] lg:pr-20 px-10 py-6 lg:text-left text-center">
          <ul className="space-y-2">
            <li>
              The company operates in 1, 60,000+ sq. ft. of warehousing space.{" "}
            </li>
            <li>
              Scalability of business space as required is the model followed.
            </li>
            <li>
              The warehouse is equipped with Air Conditioned Rooms for
              temperature controlled stock storage.
            </li>
            <li>
              Multiple Cold Room (2-8 degrees) storage facilities for
              temperature sensitive drugs.
            </li>
            <li>Deep Freezer Facilities (-15°C).</li>
            <li>Online Temperature Monitoring System.</li>
            <li>
              The premises is powered with local power supply and 24-hour
              invertor back up facility.
            </li>
            <li>Conveyor Belts for efficient unloading and loading process.</li>
            <li>Computer systems with LAN facility & Wi-Fi connectivity.</li>
            <li>Conference & Meeting rooms for field force.</li>
            <li>Record maintenance rooms.</li>
            <li>Pest Control and Hygienic Work Environment.</li>
          </ul>
          {/* <p className="py-6">
            Our staff strength includes of 5 Top Management Team 10
            Mid-Management Team 20 Customer Account Executives 50+ Warehouse
            Workforce.
          </p> */}
          <p className="py-4">
            We collaborate with the best in the business, courier and freight
            companies, who are committed and resonate with our standards and
            values.
          </p>
        </div>
        <div className="w-[]">
          <div className="flex justify-center items-center flex-wrap gap-3 py-6">
            <div
              style={bginfra1}
              className="lg:w-[200px] w-[350px] h-[200px] hover:scale-110 transition-all ease-in-out bg-cover bg-no-repeat bg-center rounded-lg cursor-pointer"
            ></div>
            <div
              style={bginfra2}
              className="lg:w-[200px] w-[350px] h-[200px] hover:scale-110 transition-all ease-in-out bg-cover bg-no-repeat bg-center rounded-lg cursor-pointer"
            ></div>
            <div
              style={bginfra3}
              className="lg:w-[200px] w-[350px] h-[200px] hover:scale-110 transition-all ease-in-out bg-cover bg-no-repeat bg-center rounded-lg cursor-pointer"
            ></div>
          </div>
          <div className="flex justify-center items-center flex-wrap gap-3 py-6">
            <div
              style={bginfra4}
              className="lg:w-[200px] w-[350px] h-[200px] hover:scale-110 transition-all ease-in-out bg-cover bg-no-repeat bg-center rounded-lg cursor-pointer"
            ></div>
            <div
              style={bginfra5}
              className="lg:w-[200px] w-[350px] h-[200px] hover:scale-110 transition-all ease-in-out bg-cover bg-no-repeat bg-center rounded-lg cursor-pointer"
            ></div>
            <div
              style={bginfra6}
              className="lg:w-[200px] w-[350px] h-[200px] hover:scale-110 transition-all ease-in-out bg-cover bg-no-repeat bg-center rounded-lg cursor-pointer"
            ></div>
          </div>
          <div className="flex justify-center items-center flex-wrap gap-3 py-6">
            <div
              style={bginfra7}
              className="lg:w-[200px] w-[350px] h-[200px] hover:scale-110 transition-all ease-in-out bg-cover bg-no-repeat bg-center rounded-lg cursor-pointer"
            ></div>
            <div
              style={bginfra8}
              className="lg:w-[200px] w-[350px] h-[200px] hover:scale-110 transition-all ease-in-out bg-cover bg-no-repeat bg-center rounded-lg cursor-pointer"
            ></div>
            <div
              style={bginfra9}
              className="lg:w-[200px] w-[350px] h-[200px] hover:scale-110 transition-all ease-in-out bg-cover bg-no-repeat bg-center rounded-lg cursor-pointer"
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfraStructure;
