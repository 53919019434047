import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "../Components/Header/Header";
import Home from "../Pages/Home/Home";
import AboutUs from "../Pages/AboutUs/AboutUs";
import Services from "../Pages/Services/Services";
import OurClient from "../Pages/OurClient/OurClient";
import InfraStructure from "../Pages/InfraStructure";
import Careers from "../Pages/Careers/Career";
import Insights from "../Pages/Insights/Insights";
import ContactUs from "../Pages/Contactus/ContactUs";
import Footer from "../Components/Footer/Footer";
import TermAndConditions from "../Pages/TermAndConditions";
import PrivacyPolicy from "../Pages/PrivacyPolicy";
import ReturnPolicy from "../Pages/ReturnPolicy";
import Leadership from "../Pages/Leadership/Leadership";
import { Gallery } from "../Pages/Images/Gallery";
import DetailsPage from "../Pages/Insights/DetailsPage/DetailsPage";
import VissionMission from "../Pages/VissionMission/VissionMission";
import OurJourney from "../Pages/OurJourney/OurJourney";
import CompanyOverview from "../Pages/CompanyOverview/CompanyOverview";
import { AwardsAccolades } from "../Pages/Awards & Accolades/AwardsAccolades";
import OurStrengths from "../Pages/OurStrengths/OurStrengths";
import Carrying from "../Pages/Services/Carrying";
import Super from "../Pages/Services/Super";
import ReverseLogistics from "../Pages/Services/ReverseLogistics";
import Values from "../Pages/Services/Values";
import ColdChain from "../Pages/Services/ColdChain";
import Material from "../Pages/Services/Material";
const AppRoutes = () => {
  return (
    <Router>
      <Header />
      <Routes>
        {/* Home */}
        <Route path="/" element={<Home />} />
        <Route path="#" element={<AboutUs />} />

        {/* about us Sub page start */}

        <Route path="/about-us/leadership" element={<Leadership />} />
        <Route path="/about-us/our-strengths" element={<OurStrengths />} />
        <Route path="/about-us/vision-mission" element={<VissionMission />} />
        <Route path="/about-us/our-journey" element={<OurJourney />} />
        <Route
          path="/about-us/awards-accolades"
          element={<AwardsAccolades />}
        />
        <Route
          path="/about-us/company-overview"
          element={<CompanyOverview />}
        />
        {/* about us sub page end */}

        {/* Infrastructure Sub page start */}
        <Route path="/infrastructures/images" element={<Gallery />} />
        <Route path="/infrastructures" element={<InfraStructure />} />
        {/* Infrastructure Sub page end */}

        {/* Insights sub page start*/}
        <Route
          path="/carrying-&-forwarding-agent-services"
          element={<Carrying />}
        />
        <Route
          path="/super-stockist-super-Distributor-operations"
          element={<Super />}
        />
        <Route path="/reverse-logistics" element={<ReverseLogistics />} />
        <Route path="/value-added-services" element={<Values />} />
        <Route path="/cold-chain-distribution" element={<ColdChain />} />
        <Route path="/promotional-material-handling" element={<Material />} />
        <Route path="/insights/lorem-ipsum-dolor" element={<DetailsPage />} />

        <Route path="/services" element={<Services />} />
        <Route path="/clientele" element={<OurClient />} />
        <Route path="/infraStructure" element={<InfraStructure />} />
        <Route path="/careers" element={<Careers />} />
        {/* <Route path="/insights" element={<Insights />} /> */}
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/terms-and-conditions" element={<TermAndConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/return-policy" element={<ReturnPolicy />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default AppRoutes;
